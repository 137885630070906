import { useState, useEffect, useCallback } from 'react';
import useApi from './useApi';
import { useHistory } from 'react-router';

function useResourceEdit(name, id) {
  const [state, setState] = useState({ id });

  const getData = useApi('get');
  const updateData = useApi(
    'update',
    'Информация успешно обновлена',
    'Произошла ошибка, попробуйте ещё раз'
  );
  const deleteData = useApi(
    'delete',
    'Запись успешно удалена!',
    'Ошибка при удалении, попробуйте ещё раз!'
  );
  const history = useHistory();

  useEffect(() => {
    getData(name, id).then(setState);
  }, []);

  const onChange = useCallback(
    data => {
      setState({ ...state, ...data });
    },
    [state]
  );
    
  const onSubmit = useCallback(() => updateData(name, state), [state]);
  const onSubmitAndReturn = useCallback(() => {
    onSubmit().then(() => history.push(`/${name}`));
  }, [onSubmit]);
  const onClone = useCallback(() => {
    history.push(`/${name}/create?id=${state.id}`);
  }, [state]);
  const onDelete = useCallback(() => {
    deleteData(name, state.id).then(() => history.push(`/${name}`));
  }, [state]);

  return {
    data: state,
    onChange,
    onSubmit,
    onSubmitAndReturn,
    onClone,
    onDelete,
  };
}

export default useResourceEdit;
