import ActionButtons from '../../shared/ActionButtons';
import PageForm from './PageForm';
import { Panel } from 'rsuite';
import React from 'react';
import { getBlock } from './blocks';
import useResourceEdit from '../../../hooks/useResourceEdit';
import withLoader from '../../hocs/withLoader';

const getRootBlock = type => {
  if (!type) {
    return PageForm;
  }
  return getBlock(type);
};

const StaticsEdit = props => {
  const {
    data,
    onChange,
    onSubmitAndReturn,
    onSubmit,
    onDelete,
  } = useResourceEdit('statics', props.match.params.id);
  const Form = getRootBlock(data?.type);

  const addBlock = (value) => {
    data.blocks.push(value);
    onSubmit();
  }

  return (
    <Panel header="Редактировать статичесткую страницу">
      <Form formValue={data} onChange={onChange} addBlock={addBlock} />
      <ActionButtons
        onSubmit={onSubmit}
        onSubmitAndReturn={onSubmitAndReturn}
        onDelete={onDelete}
      />
    </Panel>
  );
};

export default withLoader(StaticsEdit);
