import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CitiesList from './CitiesList';
import CitiesCreate from './CitiesCreate';
import CitiesEdit from './CitiesEdit';

const Cities = () => (
  <Switch>
    <Route path="/cities" component={CitiesList} exact />
    <Route path="/cities/create" component={CitiesCreate} />
    <Route path="/cities/:id" component={CitiesEdit} />
  </Switch>
);

export default Cities;
