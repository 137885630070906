import range from 'lodash/range';
import findIndex from 'lodash/findIndex';
import memo from './memo';

const getElementsByLine = memo(function(childrenCount, layout, defaultWidth) {
  return range(childrenCount).reduce(
    (acc, current) => {
      const { list, line, width } = acc;
      const indexInLayout = current + 1;
      const currentWidth = layout[indexInLayout] || defaultWidth;
      const newCalculatedWidth = width + currentWidth;

      if (newCalculatedWidth >= 100) {
        return {
          list: [...list, { line: line + 1, width: currentWidth, globalIndex: current }],
          width: currentWidth,
          line: line + 1,
        };
      }

      return {
        list: [...list, { line, width: currentWidth, globalIndex: current }],
        width: newCalculatedWidth,
        line,
      };
    },
    {
      list: [],
      width: 0,
      line: 1,
    }
  ).list;
});

export function calculateMargin(
  currentElement,
  childrenCount,
  layout,
  defaultWidth = 24,
  maxMargin = 2
) {
  const elementsByLine = getElementsByLine(childrenCount, layout, defaultWidth);
  const { line, globalIndex } = elementsByLine[currentElement];
  const oneLineElements = elementsByLine.filter(
    element => element.line === line
  );
  const isLast =
    oneLineElements.length === 1 ||
    findIndex(oneLineElements, o => o.globalIndex === globalIndex) ===
      oneLineElements.length - 1;

  if (isLast) {
    return 0;
  }

  const allElementsWidth = oneLineElements.reduce(
    (acc, curr) => acc + curr.width,
    0
  );
  const margin = (100 - allElementsWidth) / (oneLineElements.length - 1);
  return margin > maxMargin ? maxMargin : margin;
}
