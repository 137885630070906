import { Button, Form, FormControl } from 'rsuite';

import Field from '../../shared/Field';
import FieldGroup from '../../shared/FieldGroup';
import React from 'react';
import TextAreaField from '../../shared/TextAreaField';
import _ from 'lodash/fp';
import { getBlock } from './blocks';
import styles from './styles.module.sass';

const newBlock = {
  headline:'',
  list: [],
  text: '',
  right: false,
  image:null,
  type:'count'
}

const PageForm = ({ formValue, onChange,addBlock }) => {
  const data = React.useMemo(() => formValue, [formValue]);
  const blocks = data?.blocks ?? [];
  const changeField = name => value => onChange(_.set(name, value, data));
  const changeBlock = index => value =>
    onChange(_.set(`blocks[${index}]`, value, data));
  return (
    <Form formValue={data} onChange={onChange} fluid>
      <FieldGroup>
        <Field title="Заголовок">
          <FormControl name="title" disabled />
        </Field>
        <Field title="Ссылка">
          <FormControl name="slug" disabled />
        </Field>
      </FieldGroup>
      <FieldGroup title={'SEO Информация'}>
        <Field title="Seo Заголовок">
          <FormControl
            value={data?.seo?.title}
            onChange={changeField('seo.title')}
          />
        </Field>
        <Field title="Seo Описание">
          <FormControl
            value={data?.seo?.description}
            onChange={changeField('seo.description')}
            accepter={TextAreaField}
          />
        </Field>
      </FieldGroup>

      <FieldGroup title={'Блоки'} allFull>
        {blocks.map((x, i) => {
          const Block = getBlock(x.type);
          if (!Block) {
            return null;
          }
          return (
            <div className={styles.blockWrapper}>
              <span className={styles.blockLabel}>{i + 1}</span>
              <div className={styles.block}>
                <Block formValue={x} onChange={changeBlock(i)} />
              </div>
            </div>
          );
        })}
      </FieldGroup>
      <Button onClick={() => addBlock(newBlock)}>Добавить Блок</Button>
    </Form>
  );
};

export default PageForm;
