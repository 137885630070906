import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UsersList from './UsersList';
import UsersDetail from './UsersDetail';

const Users = () => (
  <Switch>
    <Route path="/users" component={UsersList} exact />
    <Route path="/users/:id" component={UsersDetail} />
  </Switch>
);

export default Users;
