import { Alert, Button, Divider, Panel } from 'rsuite';
import React, { useEffect, useMemo, useState } from 'react';

import ActionsPanel from '../../shared/ActionPanel';
import DataTable from '../../shared/DataTable';
import axios from 'axios'
import { mapCreatedAt } from '../../../utils/mapDataDates';
import useResourceList from '../../../hooks/useResourceList';
import withLoader from '../../hocs/withLoader';

const dataJSON = JSON.parse(localStorage.getItem('data'));

const columnsConfig = [
  {
    title: 'Название',
    dataKey: 'title',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Дата создания',
    dataKey: 'createdAt',
    width: 200,
  },
];

const loadFile = async (file) => {
  console.log('halo?')
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/loadconfig`,
    file
  );
  console.log(res);
  if (res.status === 200) Alert.success('Данные успешно обновлены');
};

const StaticsList = props => {
  const { data, handleDelete } = useResourceList('statics');
  const mapped = useMemo(() => mapCreatedAt(data), [data]);
  const [file, setFile] = useState(null);

  const handleFile = (e) => {
    setFile(e.target.files[0])
  };

  useEffect(() => {
    if (file !== null) {
      const formData = new FormData();
      formData.append('file', file);
      loadFile(formData);
    }
  },[file]);

  return (
    <Panel header="Статичесткие страницы">
      <Button style={{padding: 0, marginLeft: '10px'}}>
        <label
          htmlFor='loadonline'
          style={{
            padding: '8px 12px',
            display: 'inline-block',
            cursor: 'pointer',
          }}
        >
          Загрузить статический файл с данными
          <input
            id='loadonline'
            type='file'
            name='online'
            onChange={handleFile}
            style={{display: 'none'}}
          />
        </label>
      </Button>
      <Divider></Divider>
      <DataTable
        data={mapped.filter(d => d.title)}
        resourceName="statics"
        columns={columnsConfig}
        onDelete={handleDelete}
      />
    </Panel>
  );
};

export default withLoader(StaticsList);
