import React from 'react'
import {Form,FormControl,ControlLabel,FormGroup,Input,Button} from 'rsuite'

function MailBDBlock({onChange,formValue,handleSubmit}) {
    return (
        <Form style={{margin:'5vh 0'}}>
            <FormGroup>
                <h4>От кого</h4>
                <FormControl name='from' value={formValue.emailForm.from} onChange={(v) => onChange({...formValue,emailForm:{...formValue.emailForm,from:v}})}></FormControl>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Заголовок письма</ControlLabel>
                <FormControl name='title' value={formValue.emailForm.title} onChange={(v) => onChange({...formValue,emailForm:{...formValue.emailForm,title:v}})}></FormControl>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Тело письма</ControlLabel>
                <Input componentClass='textarea' rows={3} style={{width: 300}} name='text' value={formValue.emailForm.text} onChange={(v) => onChange({...formValue,emailForm:{...formValue.emailForm,text:v}})}></Input>
            </FormGroup>
            <Button type='submit' onClick={handleSubmit}>Отправить</Button>
        </Form>
    )
}

export default MailBDBlock
