import SchemaForm from '@rsuite/schema-form';
import { Schema } from 'rsuite';
import FileField from '../../../shared/FileField';

const { StringType, ObjectType } = Schema.Types;

const VideoBlock = SchemaForm([
  {
    key: 'headline',
    label: 'Заголовок',
    type: StringType().isRequired('Необходимо заполнить')
  },
  {
    key: 'video',
    type: ObjectType().isRequired('Необходимо заполнить'),
    label: 'Видео',
    componentClass: FileField,
  },
]);

export default VideoBlock;
