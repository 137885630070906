const delay = ms => new Promise(r => setTimeout(r, ms));

const baseUrl =
  process.env.REACT_APP_API_URL ?? 'http://cme-cpd.com.ua:1337/admin';
const apiUrl = `${baseUrl}/api`;
const fileUrl = `${baseUrl}/file`;

const request = (module, action, params, locale) =>
  fetch(apiUrl, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({
      module,
      action,
      params,
      locale,
    }),
  })
    .then(r => r.json())
    .then(x => x.result);

export default class ApiClient {
  locale = 'ua';
  getList(name) {
    return request(name, 'getList', null, this.locale);
  }
  get(name, id) {
    return request(name, 'get', id, this.locale);
  }
  update(name, data) {
    console.log(name,data,'admin-next/serc/api/ApiClient.js');
    return request(name, 'update', data, this.locale);
  }
  create(name, data) {
    return request(name, 'create', data, this.locale);
  }
  delete(name, id) {
    return request(name, 'delete', id, this.locale);
  }
  getOptions(name) {
    return request(name, 'getOptions', null, this.locale);
  }
  uploadFile(file) {
    const body = new FormData();
    body.append('file', file);
    return fetch(fileUrl, { method: 'POST', body })
      .then(x => x.json())
      .then(x => x.result);
  }
}
