import React,{useState} from 'react'
import {FormControl,FormGroup,ControlLabel,Form,Modal,Button,Alert,Input} from 'rsuite'
import axios from 'axios'
import EmailForm from './EmailForm'
import SmsForm from './SmsForm'

function ModalEmail({show,onHide,toWhom}) {

    const sendTo = toWhom.map(data => (data.email))
    const phones = toWhom.map(data => data.phone)

    const [formValue, setFormValue] = useState({
        emailForm: {
            from:'',
            title:'',
            text:'',
        }
    });

    const [templateValue,setTemplateValue] = useState({
        title:'',
        text:''
    })

    const [smsForm,setSmsForm] = useState({
        recipients: [],
        viber: {
            isSelected:false,
            sender:'',
            text:''
        },
        sms: {
            isSelected:false,
            sender:'',
            text:''
        },
        telegram: {
            isSelected:false,
            sender:'',
            text:''
        }
    })

    const handleSubmit = async (e) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/mail`,{...formValue,toWhom:sendTo.join(',')});
 
            if(res.status === 200) {
                Alert.success('Ваше сообщение успешно отправлено')
                setFormValue(
                    {
                        emailForm: {
                            from: '',
                            title:'',
                            text:'',
                        }
                    }
                )
            }
        } catch (error) {
            console.log(error,'from emailForm')
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>Отправка отсортированным пользователям</Modal.Header>
            <Modal.Body>
                <Form fluid>
                <h4>Email</h4>
                <EmailForm onChange={setFormValue} formValue={formValue} handleSubmit={handleSubmit}></EmailForm>
                <h4>SMS,telegram,viber рассылка</h4>
                <SmsForm smsForm={smsForm} onChange={setSmsForm}></SmsForm>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEmail
