import {Alert, Button, List, Panel} from 'rsuite';
import React, {useEffect, useState} from 'react';

import CustomLoader from '../../shared/CustomLoader';
import axios from 'axios';

function LoadSertificate() {
  // call to instance from back make SertRepo and get methods from there,then make use of useEffect to that route
  const [file, setFile] = useState(null);
  const [certificates, setCertificate] = useState(null);
  const handleChange = (e) => setFile(e.target.files[0]);
  const [agreement, setAgreement] = useState(null);
  const hadleAgreement = (e) => setAgreement(e.target.files[0]);
  const [online, setOnline] = useState(null);
  const handleOnline = (e) => setOnline(e.target.files[0]);

  const loadFile = async (file) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/loadcertificate`,
      file
    );
    if (res.status === 200) Alert.success('Сертификат успешно загружен');
  };

  const loadOnline = async (file) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/loadOnlineCert`,
      file
    );
    if (res.status === 200) Alert.success('Сертификат успешно загружен');
  };

  const loadAgreement = async (file) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/loadAgreement`,
      file
    );
    if (res.status === 200) Alert.success('Cоглашение загружено');
  };

  useEffect(() => {
    if (file !== null) {
      const formData = new FormData();
      formData.append('file', file);
      loadFile(formData);
    }

    if (agreement !== null) {
      const formData = new FormData();
      formData.append('file', agreement);
      loadAgreement(formData);
    }

    if (online !== null) {
      const formData = new FormData();
      formData.append('file', online);
      loadOnline(formData);
    }
  }, [file, agreement, online]);

  useEffect(() => {
    async function getCertificates() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getCertificates`
        );
        setCertificate(res.data);
      } catch (error) {
        Alert.error('Ошибка во время загрузки');
      }
    }

    getCertificates();
  }, []);

  if (certificates === null) return <CustomLoader></CustomLoader>;

  return (
    <Panel header='Загрузить Сертификат'>
      {/* <Button style={{padding: 0}}>
        <label
          htmlFor='loadsert'
          style={{
            padding: '8px 12px',
            display: 'inline-block',
            cursor: 'pointer',
          }}
        >
          Добавить
          <input
            id='loadsert'
            type='file'
            name='sertificate'
            onChange={handleChange}
            style={{display: 'none'}}
          />
        </label>
      </Button> */}
      <Button style={{padding: 0, marginLeft: '10px'}}>
        <label
          htmlFor='loadonline'
          style={{
            padding: '8px 12px',
            display: 'inline-block',
            cursor: 'pointer',
          }}
        >
          Добавить
          <input
            id='loadonline'
            type='file'
            name='online'
            onChange={handleOnline}
            style={{display: 'none'}}
          />
        </label>
      </Button>
      <Button style={{padding: 0, marginLeft: '20px'}}>
        <label
          htmlFor='loadagreement'
          style={{
            padding: '8px 12px',
            display: 'inline-block',
            cursor: 'pointer',
          }}
        >
          Загрузить пользовательское соглашение
          <input
            id='loadagreement'
            type='file'
            name='agreement'
            onChange={hadleAgreement}
            style={{display: 'none'}}
          />
        </label>
      </Button>

      <List style={{marginTop: '30px'}}>
        {certificates.map((c) => {
          console.log(c.filename.slice(2));
          return (
            <List.Item key={c}>
              <img style={{height: '300px'}} src={c.filename.slice(2)}></img>
            </List.Item>
          );
        })}
      </List>
    </Panel>
  );
}

export default LoadSertificate;
