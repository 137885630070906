import {Button, Form, List} from 'rsuite';

import React from 'react';
import styles from './index.module.sass';
import useFormArray from './useFormArray';

const BaseArrayField = ({
  name,
  templateElement,
  resourceLabel,
  children,
  canAdd = true,
  canRemove = true,
  IterateForm = Form,
  isDragAndDrop = false,
  lections,
  handleSortEnd,
  stopSorting,
  setStopSorting,
  isOpen,
}) => {
  const {value, change, add, remove} = useFormArray(name, templateElement);

  const [sort, setSort] = React.useState(true);

  const toggleItem = (id) => {
    console.log(
      lections.map((lec) =>
        lec.id === id ? {...lec, isOpen: !lec.isOpen} : lec
      )
    );
    return lections.map((lec) =>
      lec.id === id ? {...lec, isOpen: !lec.isOpen} : lec
    );
  };

  function alphabetically(ascending) {
    return function (a, b) {
      // equal items sort equally
      if (a.sortId === b.sortId) {
        return 0;
      }
      // nulls sort after anything else
      else if (a.sortId === null) {
        return 1;
      } else if (b.sortId === null) {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
        return a.sortId < b.sortId ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return a.sortId < b.sortId ? 1 : -1;
      }
    };
  }

  return (
    <>
      <List sortable={sort} onSort={handleSortEnd}>
        {stopSorting
          ? lections?.map((x, i) => {
              return (
                <List.Item key={i} index={i} style={{overflow: 'hidden'}}>
                  <div
                    className={styles.wrapper}
                    style={
                      isOpen
                        ? {height: '100%', margin: '40px'}
                        : {height: '30px', margin: 0}
                    }
                  >
                    <div className={styles.left}>
                      {resourceLabel} {i + 1}.
                    </div>
                    <div className={styles.form}>
                      {canRemove && (
                        <Button onClick={remove(i)} className={styles.remove}>
                          Удалить
                        </Button>
                      )}
                      <IterateForm
                        formValue={x}
                        onChange={change(i)}
                        sortId={i}
                        onLeave={setSort}
                        onOver={setSort}
                      >
                        {children && children.length
                          ? React.cloneElement(<>{children}</>)
                          : null}
                      </IterateForm>
                    </div>
                  </div>
                </List.Item>
              );
            })
          : lections?.sort(alphabetically(true)).map((x, i) => {
              return (
                <List.Item key={i} index={i} style={{overflow: 'hidden'}}>
                  <div
                    className={styles.wrapper}
                    style={
                      isOpen
                        ? {height: '100%', margin: '40px'}
                        : {height: '30px', margin: 0}
                    }
                  >
                    <div className={styles.left}>
                      {resourceLabel} {i + 1}.
                    </div>
                    <div className={styles.form}>
                      {canRemove && (
                        <Button onClick={remove(i)} className={styles.remove}>
                          Удалить
                        </Button>
                      )}
                      <IterateForm
                        formValue={x}
                        onChange={change(i)}
                        sortId={i}
                        onLeave={setSort}
                        onOver={setSort}
                      >
                        {children && children.length
                          ? React.cloneElement(<>{children}</>)
                          : null}
                      </IterateForm>
                    </div>
                  </div>
                </List.Item>
              );
            })}
      </List>

      <div className={styles.labelWrapper}>
        <span className={styles.label}>{resourceLabel}</span>
        {canAdd && (
          <Button
            onClick={() => {
              add();
              setStopSorting(true);
            }}
          >
            Добавить
          </Button>
        )}
      </div>
    </>
  );
};

export default BaseArrayField;
