import React from 'react';
import SchemaForm from '@rsuite/schema-form/lib';
import { DatePicker, Schema } from 'rsuite';
import FileField from '../../shared/FileField';

const { StringType, ObjectType, DateType } = Schema.Types;

const LecturersFormInner = SchemaForm([
  {
    key: 'id',
    label: 'Id',
    componentProps: {
      disabled: true,
    },
  },
  {
    key: 'name',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Имя',
  },
  {
    key: 'lastName',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Фамилия',
  },
  {
    key: 'patronymic',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Отчество',
  },
  {
    key: 'rank',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Звание',
  },
  {
    key: 'image',
    type: ObjectType(),
    label: 'Фото',
    componentClass: FileField,
  },
  {
    key: 'createdAt',
    type: DateType(),
    label: 'Дата создания',
    componentClass: DatePicker,
    componentProps: {
      disabled: true,
    },
  },
]);

const LecturersForm = props => {
  return <LecturersFormInner fluid {...props} />;
};

export default LecturersForm;
