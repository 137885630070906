import React from 'react';
import { Panel } from 'rsuite';
import CategoriesForm from './CategoriesForm';
import useResourceEdit from '../../../hooks/useResourceEdit';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';

const CategoriesEdit = props => {
  const {
    data,
    onChange,
    onSubmit,
    onDelete,
    onSubmitAndReturn,
  } = useResourceEdit('categories', props.match.params.id);
  return (
    <Panel header="Редактировать категорию">
      <CategoriesForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onDelete={onDelete}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(CategoriesEdit);
