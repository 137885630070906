import React from 'react';
import SchemaForm from '@rsuite/schema-form';
import { Schema, DatePicker } from 'rsuite';
import TextEditor from '../../shared/TextEditor';
const { StringType, DateType } = Schema.Types;

const FaqFormInner = SchemaForm([
  {
    key: 'id',
    label: 'Id',
    componentProps: {
      disabled: true,
    },
  },
  {
    key: 'question',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Вопрос',
  },
  {
    key: 'answer',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Ответ',
    componentClass: TextEditor,
  },
  {
    key: 'createdAt',
    type: DateType(),
    label: 'Дата создания',
    componentClass: DatePicker,
    componentProps: {
      disabled: true,
    },
  },
  {
    key: 'updatedAt',
    type: DateType(),
    label: 'Дата последнего изменения',
    componentClass: DatePicker,
    componentProps: {
      disabled: true,
    },
  },
]);

const FaqForm = props => {
  return <FaqFormInner fluid {...props} />;
};

export default FaqForm;
