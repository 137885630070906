import React, {useState, useEffect} from 'react';
import {Panel, TagPicker, Alert, Button} from 'rsuite';
import CustomLoader from '../../shared/CustomLoader';
import axios from 'axios';
import EmailForm from './EmailForm';
import TemplateEmail from './TemplateEmail';
import SmsForm from './SmsForm';
import BdTemplate from './BdTemplate';

function UsersEmail() {
  // const [formValue, setFormValue] = useState({
  //     toWhom: [],
  //     emailForm: {
  //         from:'',
  //         title:'',
  //         text:'',
  //     }
  // });

  const [templateValue, setTemplateValue] = useState({
    title: '',
    body: '',
    points: '',
  });

  const [bdTemplate, setBdTemplate] = useState({
    title: '',
    body: '',
  });

  // const [smsForm,setSmsForm] = useState({
  //     recipients: [],
  //     viber: {
  //         isSelected:false,
  //         sender:'',
  //         text:''
  //     },
  //     sms: {
  //         isSelected:false,
  //         sender:'',
  //         text:''
  //     },
  //     telegram: {
  //         isSelected:false,
  //         sender:'',
  //         text:''
  //     }
  // })

  // const [users, setUsers] = useState(null)

  useEffect(() => {
    async function getTemplate() {
      const emailTemplate = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/getTemplate`
      );
      const bdTemplate = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/getBdTemplate`
      );

      setTemplateValue(emailTemplate.data);
      setBdTemplate(bdTemplate.data);
    }

    getTemplate();
  }, []);

  // useEffect(() => {
  //     async function getUsers() {
  //         const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/getallusers`);
  //         const users = res.data.users;
  //         const profiles = res.data.profiles;
  //         const data = [];

  //         for(let u of users) {
  //             for(let p of profiles) {
  //                 if(u.id === p.userId) data.push({value:u.email,label:`${p.firstName} ${p.lastName}`});
  //             }
  //         }

  //         setUsers(data);
  //     }

  //     getUsers();
  // },[formValue])

  // const handleSubmit = async (e) => {
  //     try {
  //         const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/mail`,{...formValue,toWhom:formValue.toWhom.join(',')});

  //         if(res.status === 200) {
  //             Alert.success('Ваше сообщение успешно отправлено')
  //             setFormValue(
  //                 {
  //                     toWhom: [],
  //                     emailForm: {
  //                         from: '',
  //                         title:'',
  //                         text:'',
  //                     }
  //                 }
  //             )
  //         }
  //     } catch (error) {
  //         console.log(error,'from emailForm')
  //     }
  // }

  const handleSave = async (e) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/updateTemplate`,
        templateValue
      );

      if (res.status === 200) Alert.success('Изменения успешно сохранены');
    } catch (error) {
      console.log(error, 'from emailForm');
    }
  };

  const handleBdSave = async (e) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/updateBdTemplate`,
        bdTemplate
      );

      if (res.status === 200) Alert.success('Изменения успешно сохранены');
    } catch (error) {
      console.log(error, 'from emailForm');
    }
  };

  // if(users === null) return <CustomLoader></CustomLoader>

  return (
    <Panel header='Настройки email рассылок'>
      {/* <h4>Кому хотите отправить</h4>
            <TagPicker value={formValue.toWhom} data={users} onChange={v => setFormValue({...formValue,toWhom:v})}></TagPicker>
            <EmailForm onChange={setFormValue} formValue={formValue} handleSubmit={handleSubmit}></EmailForm> */}
      <h4>Шаблон письма при регистрации</h4>
      <TemplateEmail
        onChange={setTemplateValue}
        templateValue={templateValue}
        handleSave={handleSave}
      ></TemplateEmail>
      <h4>Шаблон письма поздравления с днем рождения</h4>
      <BdTemplate
        onChange={setBdTemplate}
        templateValue={bdTemplate}
        handleSave={handleBdSave}
      ></BdTemplate>
      {/* <h4>Шаблон письма при подтверждении</h4>
            <h4>Шаблон поздравления</h4> */}

      {/* <h4>SMS,telegram,viber рассылка</h4>
            <SmsForm smsForm={smsForm} users={users} onChange={setSmsForm}></SmsForm> */}
    </Panel>
  );
}

export default UsersEmail;
