import React,{useCallback,useState} from 'react'
import {Form,FormControl,List,SelectPicker,ControlLabel,FormGroup,Button,Alert} from 'rsuite'
import { slugFromString } from '../../../utils/beforeChange';
import Field from '../../shared/Field';
import CustomLoader from '../../shared/CustomLoader'
import Downloadxlxs from '../stats/downloadxlxs';
import axios from 'axios';
import formatDate from '../../../utils/formatDate'
import moment from 'moment';
import 'moment/locale/ru';

function UserForm({formValue, onChange,info,userId,cities,specialities,balance}) {
    const [isVisible, setIsVisible] = useState({
        spec:true,
        city:true
    })

    const handleChange = useCallback((value) => {
        onChange(slugFromString(value, 'title'));
    }, []);
      
      if(info === null || formValue === undefined) return <CustomLoader></CustomLoader>

      const {completed,eventsRegistration,courses,events,eventsDates} = info;

      const filteredEvents = events.map(e => {
          
        let event = {}
        
        for(let dates of eventsDates) {
            if(dates.event_id === e.id) {
                 event = {
                    ...event,
                    city: cities.find(c => c.city_id === dates.city_id)?.title,
                    price: `${dates.price} ${dates.priceLabel}`,
                    start:dates.start,
                    end:dates.end
                }
            }
        }

        for(let registr of eventsRegistration) {
            if(e.id === registr.eventId) {
                if(registr.email == formValue.email.trim()) return  {...event,title:e.title}
            }
          }

        return event;
      }).filter(e => e.title !== undefined);

     const filteredCourses = completed.filter(cours => cours.userId == userId);

      const coursesData = courses.map(c => {
          for(let x of filteredCourses) {
              if(x.courseId == c.id ) {
                if(c.title !== undefined) return c.title ;
              }
          }
      }).filter(c => c !== undefined)

      const defaultCity = cities.find(c => c.city_id == formValue.city) !== undefined ? cities.find(c => c.city_id == formValue.city).title : '';
      const defaultSpec = specialities.find(s => s.speciality_id.toString() === formValue.specialization) !== undefined ? specialities.find(s => s.speciality_id.toString() === formValue.specialization).title : ''

      const dataToPrint = {};

      dataToPrint['Имя'] = formValue.firstName;
      dataToPrint['Фамилия'] = formValue.firstName;
      dataToPrint['Отчество'] = formValue.patronymic;
      dataToPrint['Почта'] = formValue.email;
      dataToPrint['Номер Диплом'] = formValue.diploma;
      dataToPrint['Специализация'] = defaultSpec;
      dataToPrint['Город'] = defaultCity;
      dataToPrint['На какие мероприятия зарегистрировался'] = filteredEvents.map(event =>  event.title).join(',');
      dataToPrint['Какие курсы прошел пользователь'] = coursesData.map(c => c).join(',');
      dataToPrint['Сколько на счету'] = balance.points;
      dataToPrint['Осталось'] = balance.pointsLeft;
      
        const banUser = async () => {
          try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/banUser`,{id:formValue.id});

            if(res.status === 200) {
                Alert.success('Пользователь успешно заблокирован')
            }
          } catch (error) {
              console.log(error);
              Alert.error('Ошибка при блокировке')
          }
        }

        const unbanUser = async () => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/unbanUser`,{id:formValue.id});

                if(res.status === 200) {
                    Alert.success('Пользователь успешно заблокирован')
                }
            } catch (error) {
                console.log(error);
                Alert.error('Ошибка при блокировке')
            }
         }

    return (    
        <Form formValue={formValue} onChange={handleChange} fluid>
            <Field title='Имя'>
                <FormControl name='firstName'></FormControl>
            </Field>
            <Field title='Фамилия'>
                <FormControl name='lastName'></FormControl>
            </Field>
            <Field title='Отчество'>
                <FormControl name='patronymic'></FormControl>
            </Field>
            <Field title='Почта'>
                <FormControl name='email'></FormControl>
            </Field>
            <Field title='Дата окончания университета'>
                <FormControl name='universityEndDate'></FormControl>
            </Field>
            <Field title='Номер Дипломa'>
                <FormControl name='diploma'></FormControl>
            </Field>
            
            <FormGroup>
                <h5>Последний раз был в сети:<span style={{color:'#D62915'}}> {formValue.lastSeen !== null ? moment(formValue.lastSeen).format('LLL') : 'Давно' }</span>  </h5>
            </FormGroup>

            <FormGroup>
                <ControlLabel><h5>Баллы</h5></ControlLabel>
                <ControlLabel>Сколько на счету: {balance.points}</ControlLabel>
                <ControlLabel>Осталось: {balance.pointsLeft} </ControlLabel>
            </FormGroup>

           <FormGroup style={{position:'relative'}}>
                <ControlLabel>Специализация</ControlLabel>
                {isVisible.spec && <div style={{width:'400px',position: "absolute",left: "120px",top: "0px",background:'#fff'}}>Текущая: <span style={{color:'#D62915'}}>{defaultSpec}</span></div>} 
                <SelectPicker  onChange={v => {
                    setIsVisible({...isVisible,spec:false})
                    handleChange({...formValue,specialization:v})
                }} 
                data={specialities.map(c => ({
                    ...c,
                    label:c.title,
                    value: c.speciality_id
                }))}></SelectPicker>
            </FormGroup>

            <FormGroup style={{position:'relative'}}>
                <ControlLabel>Город</ControlLabel>
               {isVisible.city && <div style={{width:'180px',position: "absolute",left: "50px",top: "0px",background:'#fff'}}>Текущий: <span style={{color:'#D62915'}}>{defaultCity}</span> </div>} 
                <SelectPicker onChange={v => {
                    setIsVisible({...isVisible,city:false})
                    handleChange({...formValue,city:v})
                }} 
                data={cities.map(c => ({
                    ...c,
                    label:c.title,
                    value: c.id
                }))}
                ></SelectPicker>

            </FormGroup>

            <h4>На какие мероприятия зарегистрировался</h4>
            {filteredEvents.length === 0 ? <p style={{margin:'5vh 0'}}>Пусто</p> : <List style={{marginBottom: '5vh'}}>
            {filteredEvents.map(event =>  <List.Item>{event.title} <span style={{color:'#D62915'}}>Город:</span> {event.city} 
            {' '}
            <span style={{color:'#D62915'}}>Цена:</span> {event.price}
            {' '}
            <span style={{color:'#D62915'}}>Начало:</span> {formatDate(event.start)}
            {' '}
            <span style={{color:'#D62915'}}>Конец:</span> {formatDate(event.end)}
            </List.Item>)}
            </List>}

            <h4>Какие курсы прошел пользователь</h4>
            {coursesData.length === 0 ? <p style={{margin:'5vh 0'}}>Пусто</p> : <List bordered={false} style={{marginBottom: '30px'}}>
                {coursesData.map(c => <List.Item>{c}</List.Item>)}
            </List>}
            
            <Downloadxlxs data={[dataToPrint]} fileName={`Пользователь ${formValue.firstName} ${formValue.lastName}`}></Downloadxlxs>
            {
            formValue.isBlocked === 'true' ? 
            <Button color='green' style={{marginLeft: '20px'}} onClick={() => unbanUser()}>Разблокировать</Button>
                :
                <Button color='red' style={{marginLeft: '20px'}} onClick={() => banUser()}>Заблокировать</Button> 
             }  
        </Form>
    )
    
}

export default UserForm
