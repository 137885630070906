import React,{useState} from 'react';
import { Col, Grid, Panel, Row,Button,Alert } from 'rsuite';
import styles from './detail.module.sass';
import useResourceItem from '../../../hooks/useResourceItem';
import withLoader from '../../hocs/withLoader';
import formatDate from '../../../utils/formatDate';
import FeedbackAnswer from './FeedbackAnswer'
import axios from 'axios'

const Item = ({ title, children }) => (
  <Row className={styles.row}>
    <Col md={4} className={styles.titleCol}>
      {title}
    </Col>
    <Col md={20}>{children}</Col>
  </Row>
);

const FeedbackDetail = props => {
  const { data } = useResourceItem('feedbacks', props.match.params.id);

  const [isOpen, setIsOpen] = useState(false)

  const [formValue, setFormValue] = useState({
      emailForm: {
          from:'',
          title:'',
          text:'',
      }
  });

  const handleSubmit = async (e) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/mail`,{...formValue,toWhom:`${data.email}`});

        if(res.status === 200) {
            Alert.success('Ваше сообщение успешно отправлено');
            setIsOpen(false)
            setFormValue(
                {
                    emailForm: {
                        from: '',
                        title:'',
                        text:'',
                    }
                }
            )
        }
    } catch (error) {
        console.log(error,'from emailForm')
    }
}

  return (
    <Panel header="Обратная связь">
      <Grid fluid>
        <Item title="Email">{data.email}</Item>
        <Item title="Имя">{data.name}</Item>
        <Item title="Фамилия">{data.lastName}</Item>
        <Item title="Когда">{formatDate(data.createdAt)}</Item>
        <Item title="Текст">{data.message}</Item>
        <Button style={{marginTop: '20px'}} color='cyan' onClick={() => setIsOpen(true)}>Ответить</Button>
        {isOpen && <FeedbackAnswer show={() => setIsOpen(true)} onHide={() => setIsOpen(false)} onChange={setFormValue} formValue={formValue} handleSubmit={handleSubmit}></FeedbackAnswer>}
      </Grid>
    </Panel>
  );
};

export default withLoader(FeedbackDetail);
