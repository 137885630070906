function defaultEqFunc(args1, args2) {
  return (
    !!args1 &&
    args1.every((item, index) => args2 && args2[index] && item === args2[index])
  );
}

export default function memo(fn, eqFunc = defaultEqFunc) {
  let prevArgs = null;
  let prevResult = null;

  return function(...args) {
    if (!eqFunc(args, prevArgs) || !prevResult) {
      prevArgs = args;
      prevResult = fn(...args);
      return prevResult;
    }

    return prevResult;
  };
}
