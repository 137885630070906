import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EventsList from './EventsList';
import EventsCreate from './EventsCreate';
import EventsEdit from './EventsEdit';

const Events = () => (
  <Switch>
    <Route path="/events" component={EventsList} exact />
    <Route path="/events/create" component={EventsCreate} />
    <Route path="/events/:id" component={EventsEdit} />
  </Switch>
);

export default Events;
