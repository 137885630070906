import { useEffect, useMemo, useState } from 'react';

function getCurrentScreenWidth() {
  return window.innerWidth;
}

const getCurrentBreakpoint = breakpoints => {
  const currentWidth = getCurrentScreenWidth();
  return (
    breakpoints.find(breakpoint => currentWidth <= breakpoint) || 'default'
  );
};

export default function useValueByScreenWidth(initialData) {
  const breakpoints = useMemo(
    () =>
      Object.keys(initialData)
        .filter(breakpoint => /^[0-9]+$/g.test(breakpoint))
        .map(breakpoint => Number(breakpoint))
        .sort((a, b) => (a < b ? -1 : 1)),
    [initialData]
  );
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    getCurrentBreakpoint(breakpoints)
  );

  useEffect(() => {
    const handler = () => {
      const breakpointToApply = getCurrentBreakpoint(breakpoints);
      if (breakpointToApply !== currentBreakpoint) {
        setCurrentBreakpoint(breakpointToApply);
      }
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [breakpoints, currentBreakpoint]);

  return initialData[currentBreakpoint];
}
