import React from 'react';
import { Panel } from 'rsuite';
import EventsForm from './EventsForm';
import useResourceEdit from '../../../hooks/useResourceEdit';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';

const EventsEdit = props => {
  const {
    data,
    onChange,
    onSubmit,
    onClone,
    onDelete,
    onSubmitAndReturn,
  } = useResourceEdit('events', props.match.params.id);
  return (
    <Panel header="Редактировать мероприятие">
      <EventsForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onClone={onClone}
        onDelete={onDelete}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(EventsEdit);
