import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CoursesList from './CoursesList';
import CoursesCreate from './CoursesCreate';
import CoursesEdit from './CoursesEdit';

const Courses = () => (
  <Switch>
    <Route path="/courses" component={CoursesList} exact />
    <Route path="/courses/create" component={CoursesCreate} />
    <Route path="/courses/:id" component={CoursesEdit} />
  </Switch>
);

export default Courses;
