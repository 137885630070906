import React from 'react';
import LoadingContextProvider from '../contextProviders/LoadingContextProvider';
import CustomLoader from '../shared/CustomLoader';
import useLoadingContext from '../../hooks/useLoadingContext';

const InnerComponent = ({ children }) => {
  const { loading } = useLoadingContext();

  return (
    <>
      {loading && <CustomLoader />}
      {children}
    </>
  );
};

export default function withLoader(Component) {
  return props => (
    <LoadingContextProvider>
      <InnerComponent>
        <Component {...props} />
      </InnerComponent>
    </LoadingContextProvider>
  );
}
