import React, { useCallback, useMemo } from 'react';
import {
  CheckPicker,
  DatePicker,
  Form,
  FormControl,
  SelectPicker,
} from 'rsuite';
import Field from '../../shared/Field';
import TextEditor from '../../shared/TextEditor';
import FileField from '../../shared/FileField';
import styles from './form.module.sass';
import ArrayField from '../../shared/ArrayField';
import useResourceOptions from '../../../hooks/useResourceOptions';
import FieldGroup from '../../shared/FieldGroup';
import TextAreaField from '../../shared/TextAreaField';
import { slugFromString } from '../../../utils/beforeChange';

const EventsForm = ({ formValue = {}, onChange = console.log }) => {
  const { data: lecturers } = useResourceOptions('lecturers');
  const { data: cities } = useResourceOptions('cities');
  const { data: categories } = useResourceOptions('categories');
  const { data: specialities } = useResourceOptions('specialities');
  const eventDateTemplate = useMemo(() => ({
    start: Date.now(),
    end: Date.now(),
    place: cities[0]?.value,
  }));
  const handleChange = useCallback(value => {
    onChange(slugFromString(value, 'title'));
  }, []);
  return (
    <Form formValue={formValue} onChange={handleChange} fluid>
      <FieldGroup title={'Основная информация'}>
        <Field title="Название">
          <FormControl name="title" />
        </Field>
        <Field title="Ссылка">
          <FormControl name="slug" disabled />
        </Field>
        <Field title="Id">
          <FormControl name="id" disabled />
        </Field>
      </FieldGroup>
      <FieldGroup title={'SEO Информация'}>
        <Field title="SEO Заголовок">
          <FormControl name="seoTitle" />
        </Field>
        <Field title="SEO Описание">
          <FormControl name="seoDescription" accepter={TextAreaField} />
        </Field>
      </FieldGroup>
      <FieldGroup title={'Детальная информация'} layout={{ 5: 100 }}>
        <Field title="Подзаголовок">
          <FormControl name="subTitle" />
        </Field>
        <Field title="Дата начала">
          <FormControl name="date" />
        </Field>
        <Field title="Город">
          <FormControl name="city" />
        </Field>
        <Field title="Цена">
          <FormControl name="price" />
        </Field>
        <Field title="Описание">
          <FormControl name="description" accepter={TextEditor} />
        </Field>
        <Field title="Картинка">
          <FormControl name="image" accepter={FileField} />
        </Field>
        <Field title="Категория">
          <FormControl
            name="category_id"
            accepter={SelectPicker}
            data={categories}
            className={styles.checks}
          />
        </Field>
        <Field title="Специализации">
          <FormControl
            name="specialities"
            accepter={CheckPicker}
            data={specialities}
            className={styles.checks}
          />
        </Field>
        <Field title="Лекторы">
          <FormControl
            name="lecturers"
            accepter={CheckPicker}
            data={lecturers}
            className={styles.checks}
          />
        </Field>
      </FieldGroup>
      <FieldGroup title={'Даты'} column>
        <ArrayField
          name="dates"
          resourceLabel="Даты"
          templateElement={eventDateTemplate}
        >
          <FieldGroup layout={{1: 30, 2: 30, 3: 30, 4: 48, 5: 48}}>
            <Field title="Дата начала">
              <FormControl name="start" accepter={DatePicker} oneTap />
            </Field>
            <Field title="Дата окончания">
              <FormControl name="end" accepter={DatePicker} oneTap />
            </Field>
            <Field title="Место">
              <FormControl
                name="city"
                accepter={SelectPicker}
                data={cities}
                className={styles.checks}
              />
            </Field>
            <Field title="Цена">
              <FormControl name="price" type="number" />
            </Field>
            <Field title="Валюта цены">
              <FormControl name="priceLabel" />
            </Field>
          </FieldGroup>

        </ArrayField>
      </FieldGroup>
    </Form>
  );
};

export default EventsForm;
