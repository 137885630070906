import React, { useState } from 'react';
import styles from './index.module.sass';
import apiClient from '../../../api/singleton';
import { Icon } from 'rsuite';

const FileField = props => {
  const { value, onChange, ...rest } = props;
  const [uploading, setUploading] = useState(false);
  const handleChange = e => {
    setUploading(true);
    apiClient.uploadFile(e.target.files[0]).then(x => {
      console.log(x,'fileField')
       onChange(x);
      setUploading(false);
    });
  };

 // console.log(value,'FileField from pages/admin-next') // file icon name for docs type

  return (
    <>
      <input {...rest} type="file" multiple={false} onChange={handleChange} />
      {uploading ? (
        <div>Loading...</div>
      ) : value && value.type?.includes('image') ? (
        <img src={value.url} className={styles.image} />
      ) : value && value.type?.includes('video') ? (
        <div className={styles.video}>
          <Icon icon="video-camera" className={styles.icon} />{' '}
          <span>{value.name}</span>
        </div>
      ) : value && value.type?.includes('application') ? (<div className={styles.video}>
            <Icon icon="file" className={styles.icon} />{' '}
            <span>{value.name}</span>
          </div>) : (
        ''
      )}
    </>
  );
};

export default FileField;
