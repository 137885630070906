import React, { useMemo } from 'react';
import { Button, Panel } from 'rsuite';
import ActionsPanel from '../../shared/ActionPanel';
import DataTable from '../../shared/DataTable';
import useResourceList from '../../../hooks/useResourceList';
import withLoader from '../../hocs/withLoader';
import { mapCreatedAt } from '../../../utils/mapDataDates';
import {getFromStorage} from '../../../utils/localStorageAPI'

const token = getFromStorage('token');

const columnsConfig = [
  {
    title: 'Название',
    dataKey: 'title',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Дата создания',
    dataKey: 'createdAt',
    width: 200,
  },
];

const EventsList = props => {
  const { data, handleDelete } = useResourceList('events');
  const mapped = useMemo(() => mapCreatedAt(data), [data]);
  return (
    <Panel header="Мероприятия">
      <ActionsPanel>
        <Button onClick={() => props.history.push('/events/create')}>
          Создать
        </Button>
      </ActionsPanel>
      <DataTable
        data={mapped}
        resourceName="events"
        columns={columnsConfig}
        onDelete={handleDelete}
        allowActions={token.role === 'manager' ? false : true}
      />
    </Panel>
  );
};

export default withLoader(EventsList);
