import React from 'react';
import { Button, ButtonToolbar } from 'rsuite';

import styles from './ActionButtons.module.sass';

const ActionButtons = ({ onSubmit, onSubmitAndReturn, onClone, onDelete }) => {
  return (
    <ButtonToolbar className={styles.wrapper}>
      {onSubmit && (
        <Button
          onClick={onSubmit}
          appearance="primary"
          color="blue"
          className={styles.button}
        >
          Сохранить
        </Button>
      )}
      {onSubmitAndReturn && (
        <Button
          onClick={onSubmitAndReturn}
          appearance="primary"
          color="blue"
          className={styles.button}
        >
          Сохранить и выйти
        </Button>
      )}
      {onClone && (
        <Button
          onClick={onClone}
          appearance="default"
          className={styles.button}
        >
          Клонировать
        </Button>
      )}
      {onDelete && (
        <Button onClick={onDelete} color="red" className={styles.button}>
          Удалить
        </Button>
      )}
    </ButtonToolbar>
  );
};

export default ActionButtons;
