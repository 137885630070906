import React, { useMemo, useState } from 'react';
import LoadingContext from '../../../contexts/LoadingContext';

const LoadingContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const value = useMemo(() => ({ loading, setLoading }), [loading]);

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
