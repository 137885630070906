import CountBlock from './CountBlock';
import TopBlock from './TopBlock';
import VideoBlock from './VideoBlock';
import NewsBlock from './NewsBlock';
import EventsBlock from './EventsBlock';
import RegisterBlock from './RegisterBlock';
import ExpertsBlock from './ExpertsBlock';
import MapBlock from './MapBlock';
import ContactsBlock from './ContactsBlock';
import FooterBlock from './FooterBlock';

const blocksComponents = {
  count: CountBlock,
  top: TopBlock,
  video: VideoBlock,
  news: NewsBlock,
  events: EventsBlock,
  register: RegisterBlock,
  experts: ExpertsBlock,
  map: MapBlock,
  contacts: ContactsBlock,
  footer: FooterBlock,
};

export const getBlock = type => blocksComponents[type];
