import React from 'react';
import styles from './index.module.sass';

const ActionsPanel = ({ children }) => (
  <div className={styles.panel}>
    {React.Children.map(children, child => (
      <div
        className={`${styles.item} ${child.props.right ? styles.right : ''}`}
      >
        {child}
      </div>
    ))}
  </div>
);

export default ActionsPanel;
