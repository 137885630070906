import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SpecialitiesCreate from './SpecialitiesCreate';
import SpecialitiesList from './SpecialitiesList';
import SpecialitiesEdit from './SpecialitiesEdit';

const Specialties = () => (
  <Switch>
    <Route path="/specialities" component={SpecialitiesList} exact />
    <Route path="/specialities/create" component={SpecialitiesCreate} />
    <Route path="/specialities/:id" component={SpecialitiesEdit} exact />
  </Switch>
);

export default Specialties;
