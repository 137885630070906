import React, { useCallback } from 'react';
import SchemaForm from '@rsuite/schema-form';
import { Schema, DatePicker, Form, FormControl } from 'rsuite';
import TextEditor from '../../shared/TextEditor';
import FileField from '../../shared/FileField';
import FieldGroup from '../../shared/FieldGroup';
import Field from '../../shared/Field';
import { slugFromString } from '../../../utils/beforeChange';
import TextAreaField from '../../shared/TextAreaField';

const { StringType, ObjectType, DateType } = Schema.Types;

const _NewsForm = SchemaForm([
  {
    key: 'id',
    label: 'Id',
    componentProps: {
      disabled: true,
    },
  },
  {
    key: 'title',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Заголовок',
  },
  {
    key: 'slug',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Ссылка',
  },
  {
    key: 'seoTitle',
    type: StringType(),
    label: 'SEO Заголовок',
  },
  {
    key: 'seoDescription',
    type: StringType(),
    label: 'SEO Описание',
  },
  {
    key: 'subTitle',
    type: StringType(),
    label: 'Подзаголовок',
  },
  {
    key: 'description',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Описание',
    componentClass: TextEditor,
  },
  {
    key: 'image',
    type: ObjectType().isRequired('Необходимо заполнить'),
    label: 'Изображения',
    componentClass: FileField,
  },
  {
    key: 'createdAt',
    type: DateType(),
    label: 'Дата создания',
    componentClass: DatePicker,
  },
  {
    key: 'updatedAt',
    type: DateType(),
    label: 'Дата последнего изменения',
    componentClass: DatePicker,
  },
]);

const NewsForm = ({ formValue, onChange }) => {
  console.log(formValue, 'NewsForm');

  const handleChange = useCallback((value) => {
    onChange(slugFromString(value, 'title'));
  }, [])
  ;
  return (
    <Form formValue={formValue} onChange={handleChange} fluid>
      <FieldGroup title={'Основная информация'}>
        <Field title="Название">
          <FormControl name="title" />
        </Field>
        <Field title="Ссылка">
          <FormControl name="slug" disabled />
        </Field>
        <Field title="Id">
          <FormControl name="id" disabled />
        </Field>
      </FieldGroup>
      <FieldGroup title={'SEO Информация'}>
        <Field title="SEO Заголовок">
          <FormControl name="seoTitle" />
        </Field>
        <Field title="SEO Описание">
          <FormControl name="seoDescription" accepter={TextAreaField} />
        </Field>
      </FieldGroup>
      <FieldGroup title={'Детальная информация'} layout={{ 1: 100, 2: 100 }}>
        <Field title="Подзаголовок">
          <FormControl name="subTitle" accepter={TextEditor} />
        </Field>
        <Field title="Описание">
          <FormControl name="description" accepter={TextEditor} />
        </Field>
        <Field title="Изображения">
          <FormControl name="image" accepter={FileField} />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field title="Дата создания">
          <FormControl name="createdAt" accepter={DatePicker} />
        </Field>
        <Field title="Дата последнего изменения">
          <FormControl name="updatedAt" accepter={DatePicker} />
        </Field>
      </FieldGroup>
    </Form>
  );
};

export default NewsForm;
