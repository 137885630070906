import React from 'react';
import { Loader } from 'rsuite';

import './CustomLoader.sass';

const CustomLoader = () => {
  return <Loader backdrop content="Загрузка" vertical center />;
};

export default CustomLoader;
