import React from 'react';
import { Input } from 'rsuite';

const TextAreaField = props => {
  return (
    <Input
      componentClass={'textarea'}
      style={{ resize: 'vertical' }}
      {...props}
    />
  );
};

export default TextAreaField;
