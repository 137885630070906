import React, { useMemo } from 'react';
import DataTable from '../../shared/DataTable';
import { mapCreatedAt } from '../../../utils/mapDataDates';
import DownloadxlxsBtn from './downloadxlxs';

const columnsConfig = [
  {
    title: 'Пользователь',
    dataKey: 'user',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Баланс',
    dataKey: 'balance',
    flexGrow:1
  },
];

function UsersBalances({ data: { users,balances:balanceData } }) {


  const data = users.map((u) => {
    const balances = [];
 
    for(let balance of balanceData) {
      if(u.userId === balance.userId) {
        balances.push(`Баланс: ${balance.balance}. Баллы ${balance.points} .Осталось баллов: ${balance.pointsLeft}`)
      }
    }

    return {
      user: `${u.firstName} ${u.lastName}`,
      balance: balances.join(','),
    };
  });

  const mapped = useMemo(() => mapCreatedAt(data), [data]);

  const toPrint = data.map(d => ({
    ['Пользователь']:d.user,
    ['Баланс']:d.balance
  }))

  return (
    <section style={{ marginBottom: '5vh' }}>
      <h4>
      Счета пользователей{' '}
        <DownloadxlxsBtn
          data={toPrint}
          fileName="Счета пользователей"
        ></DownloadxlxsBtn>
      </h4>
      <DataTable
        data={mapped}
        allowActions={false}
        columns={columnsConfig}
      ></DataTable>
    </section>
  );
}

export default UsersBalances;
