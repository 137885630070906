import React, {useEffect, useState} from 'react';

import CertificatesInfo from './Certificates'
import CoursesInfo from './CoursesInfo';
import CustomLoader from '../../shared/CustomLoader';
import EventsInfo from './EventsInfo';
import {Panel} from 'rsuite';
import UsersBalances from './UsersBalances';
import UsersRegistred from './UsersRegistred';
import WhoPaid from './WhoPaid';
import axios from 'axios';
import withLoader from '../../hocs/withLoader';

function Index() {
  const [data, setData] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [certificatesInfo,setCertInfo] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getstats`
        );
        const usersOnline = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getOnlineUsers`
        );

        const certificates = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getCustomCert`
        );

        setData(res.data);
        setOnlineUsers(usersOnline.data);
        setCertInfo(certificates.data)
      } catch (error) {
        console.log(error);
        console.log('error stats/index.jsx');
      }
    }

    getData();
  }, []);

  return (
    <>
      <Panel header='Статистика'>
        {data === null ? (
          <CustomLoader></CustomLoader>
        ) : (
          <>
            <UsersRegistred
              data={data}
              onlineUsers={onlineUsers}
            ></UsersRegistred>
            <CoursesInfo data={data}></CoursesInfo>
            <CertificatesInfo data={certificatesInfo} users={data}></CertificatesInfo> 
            <EventsInfo data={data}></EventsInfo>
            <WhoPaid data={data}></WhoPaid>
            <UsersBalances data={data}></UsersBalances>
          </>
        )}
      </Panel>
    </>
  );
}

export default withLoader(Index);
