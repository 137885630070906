import React  from 'react';
import { Button, Input } from 'rsuite';
import styles from './index.module.sass';
import useFormArray from './useFormArray';

const ArrayTextField = ({
  name,
  resourceLabel,
  canAdd = true,
  canRemove = true,
}) => {
  const { value, change, add, remove } = useFormArray(name, '');

  return (
    <>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{resourceLabel}</span>
        {canAdd && <Button onClick={add}>Добавить</Button>}
      </div>
      {value.map((x, i) => (
        <div className={styles.wrapper}>
          <div className={styles.left}>
            {resourceLabel} {i + 1}.
          </div>
          <div className={styles.form}>
            {canRemove && (
              <Button onClick={remove(i)} className={styles.remove}>
                Удалить
              </Button>
            )}
            <Input value={value[i]} onChange={change(i)}/>
          </div>
        </div>
      ))}
    </>
  );
};

export default ArrayTextField;
