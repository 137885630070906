import apiClient from '../api/singleton';
import { useCallback } from 'react';
import { Alert } from 'rsuite';
import useLoadingContext from './useLoadingContext';

export default function useApi(method, successMessage, errorMessage) {
  const { setLoading } = useLoadingContext();

  return useCallback(
    async function(...args) {
      setLoading(true);

      try {
        const response = await apiClient[method](...args);
        successMessage && Alert.success(successMessage, 5000);
        setLoading(false);
        return response;
      } catch (e) {
        errorMessage && Alert.warning(`${errorMessage}: ${e.message}`, 5000);
        setLoading(false);
        return {};
      }
    },
    [method, successMessage, errorMessage]
  );
}
