import React, { useMemo } from 'react';
import DataTable from '../../shared/DataTable';
import { mapCreatedAt } from '../../../utils/mapDataDates';
import DownloadxlxsBtn from './downloadxlxs';

const columnsConfig = [
  {
    title: 'Название',
    dataKey: 'title',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Сколько раз прошли',
    dataKey: 'rolledCount',
    width: 150,
  },
  {
    title: 'Кто сохранил',
    dataKey: 'whoSaved',
    width: 150,
  },
];

function CoursesInfo({ data: { users, courses, completed } }) {

  function removeDuplicatesSafe(arr) {
    var seen = {};
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
      if (!(arr[i] in seen)) {
        ret_arr.push(arr[i]);
        seen[arr[i]] = true;
      }
    }
    return ret_arr;
  }

  function generateList(arr) {
    return arr.map((elem) => elem).join(',');
  }

  const data = courses.map((c) => {
    const rolledCount = [];
    const whoSaved = [];

    for (let compl of completed) {
      if (c.id === compl.courseId) {
        rolledCount.push(compl);
        for (let user of users) {
          if (user.id === compl.userId) {
            const userInSaved = {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
            };
            whoSaved.push(userInSaved);
          }
        }
      }
    }

    const withoutDuplicates = removeDuplicatesSafe(
      whoSaved.map((s) => `${s.firstName} ${s.lastName}`)
    );

    return {
      ...c,
      rolledCount: rolledCount.length,
      whoSaved: whoSaved.length === 0 ? '-' : generateList(withoutDuplicates),
    };
  });

  const mapped = useMemo(() => mapCreatedAt(data), [data]);

  return (
    <section style={{ marginBottom: '5vh' }}>
      <h4>
        Информация по курсам{' '}
        <DownloadxlxsBtn
          data={mapped}
          fileName="Информация по курсам"
        ></DownloadxlxsBtn>
      </h4>
      <DataTable
        data={mapped}
        allowActions={false}
        columns={columnsConfig}
      ></DataTable>
    </section>
  );
}

export default CoursesInfo;
