import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CategoriesList from './CategoriesList';
import CategoriesEdit from './CategoriesEdit';
import CategoriesCreate from './CategoriesCreate';

const Categories = () => (
  <Switch>
    <Route path="/categories" component={CategoriesList} exact />
    <Route path="/categories/create" component={CategoriesCreate} />
    <Route path="/categories/:id" component={CategoriesEdit} />
  </Switch>
);

export default Categories;
