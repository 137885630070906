import React,{useEffect,useState} from 'react';
import { Panel,Alert } from 'rsuite';
import useResourceEdit from '../../../hooks/useResourceEdit';
import useResourceList from '../../../hooks/useResourceList';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';
import UserForm from './UserForm'
import axios from 'axios';

const UsersDetail = props => {
  const {
   data, 
    onChange,
    onSubmit,
    onClone,
    onDelete,
    onSubmitAndReturn,
  } = useResourceEdit('users', props.match.params.id);

  const {data:specialities} = useResourceList('specialities')
  const {data:cities} = useResourceList('cities')

  const [info, setInfo] = useState(null);
  const [balance,setBalance] = useState(null);

  useEffect(() => {
      async function getData() {
          try {
              const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/getstats`);

              const balance = await axios.post(`${process.env.REACT_APP_API_URL}/api/getBalance`,{userId:props.match.params.id})
              setBalance(balance.data)
              setInfo(res.data);
          } catch (error) {
              console.log(error);
              console.log('error stats/index.jsx')
              Alert.error('Ошибка во время загрузки')
          }
      }
      getData()
  }, [])
  
  return (
    <Panel header="Информация о пользователе">
      <UserForm userId={props.match.params.id} formValue={data} onChange={onChange} cities={cities} balance={balance} specialities={specialities} info={info}/>
      <ActionButtons
        onSubmit={onSubmit}
        onClone={onClone}
        onDelete={onDelete}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(UsersDetail);
