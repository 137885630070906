import { useState, useEffect } from 'react';
import useApi from './useApi';

function useResourceItem(name, id) {
  const [state, setState] = useState({});
  const getData = useApi(
    'get',
    null,
    'Произошла ошибка при загрузке, попробуйте ещё раз!'
  );

  useEffect(() => {
    getData(name, id).then(setState);
  }, []);

  return { data: state };
}

export default useResourceItem;
