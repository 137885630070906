import React from 'react';
import {
  Form,
  FormControl,
  ControlLabel,
  FormGroup,
  Input,
  Button,
} from 'rsuite';

function TeamplateEmail({onChange, templateValue, handleSave}) {
  console.log(templateValue);
  return (
    <Form style={{margin: '5vh 0'}} fluid>
      <FormGroup>
        <ControlLabel>Заголовок</ControlLabel>
        <FormControl
          value={templateValue.title}
          name='title'
          onChange={(v) => onChange({...templateValue, title: v})}
        ></FormControl>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Тело</ControlLabel>
        <Input
          componentClass='textarea'
          value={templateValue.body}
          rows={3}
          style={{width: 300}}
          name='text'
          onChange={(v) => onChange({...templateValue, body: v})}
        ></Input>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Баллы за регистрацию</ControlLabel>
        <FormControl
          value={templateValue.points}
          name='title'
          onChange={(v) => onChange({...templateValue, points: v})}
        ></FormControl>
      </FormGroup>
      <Button type='submit' onClick={handleSave}>
        Сохранить
      </Button>
    </Form>
  );
}

export default TeamplateEmail;
