import React, { useState } from 'react';
import './App.less';
import Frame from '@rsuite/react-frame';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { navData } from './navData';
import BottomNav from './BottomNav';
import Sidebar from './Sidebar';
import { Redirect } from 'react-router';
import LoginPage from '../pages/login'
import {getFromStorage} from '../../utils/localStorageAPI'
import UsersEmail from '../pages/users/UsersEmail'

const routes = navData.reduce((acc, curr) => {
  if ('children' in curr) {
    return [...acc, ...curr.children];
  }

  return [...acc, curr];
}, []);

const token = getFromStorage('token') || null;

function App() {
  const [expanded, setExpanded] = useState(false);
  

  return (
    <div className="App">
      <Router>
        {token ? <Frame>
          <Frame.Nav
            renderTitle={() => <span>UAFM Admin</span>}
            brand={() => <img src="" alt="" />}
            expand={expanded}
            onExpand={() => setExpanded(v => !v)}
            onRenderBottomNav={() => <BottomNav expanded={expanded} />}
          >
            <Sidebar expanded={expanded} />
          </Frame.Nav>
          <Frame.Content style={{ width: '100%' }}>
    
            <Switch>
              <Redirect from={'/'} to={'/news'} exact />
              {routes.map(x => (
                <Route path={x.link} component={x.component} />
              ))}
              <Route path='/email-configuration' component={UsersEmail}></Route>
            </Switch>
          </Frame.Content>
        </Frame> : <LoginPage></LoginPage>}
  
      </Router>
    </div>
  );
}

export default App;
