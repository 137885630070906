import React, { useMemo } from 'react';
import { Table } from 'rsuite';
import useWindowSize from '../../../hooks/useWindowSize';
import * as _ from 'lodash/fp';
import createListActions from './defaultActions';


const { Column, HeaderCell, Cell } = Table;

const DataTable = ({
  data,
  resourceName,
  columns,
  renderActions,
  loading = false,
  onDelete,
  allowActions = true
}) => {
  const actions = useMemo(
    () => renderActions || createListActions(resourceName, onDelete),
    [resourceName, renderActions]
  );
  const { height } = useWindowSize();
  const tableHeight = useMemo(() => height - 200, [height]);
  return (
    <Table wordWrap height={tableHeight} {...{ data, loading }}>
      {columns.map(x => (
        <Column minWidth={400} key={x.dataKey} {..._.omit(['title', 'dataKey'], x)}>
          <HeaderCell>{x.title}</HeaderCell>
          <Cell dataKey={x.dataKey} />
        </Column>
      ))}
     {allowActions && <Column width={200}>
        <HeaderCell>Действия</HeaderCell>
        <Cell>{actions}</Cell>
      </Column>}  
    </Table>
  );
};

export default DataTable;
