import React from 'react';
import { Schema } from 'rsuite';
import SchemaForm from '@rsuite/schema-form/lib';

const { StringType } = Schema.Types;

const CategoriesFormInner = SchemaForm([
  {
    key: 'id',
    label: 'Id',
    componentProps: {
      disabled: true,
    },
  },
  {
    key: 'title',
    type: StringType().isRequired('Необходимо заполнить'),
    label: 'Название',
  },
]);

const CategoriesForm = props => {
  return <CategoriesFormInner fluid {...props} />;
};

export default CategoriesForm;
