import {FormContext, FormValueContext} from 'rsuite/es/Form/FormContext';

import {useContext} from 'react';

export default function useFormArray(name, template = {}) {
  const formValue = useContext(FormValueContext);

  const form = useContext(FormContext);
  const value = Array.isArray(formValue[name]) ? formValue[name] : [];
  const onChange = (data) => form.onFieldChange(name, data);

  const change = (index) => (data) => {
    onChange(
      value.map((x, i) => (i === index ? Object.is(typeof data,typeof 'sdf') ? data : {...data, docs_id: data.docs?.id}  : x))
    );
  };

  const add = () => {
    onChange([...value, template]);
  };

  const remove = (index) => () => {
    onChange(value.filter((x, i) => i !== index));
  };

  return {
    value,
    change,
    add,
    remove,
  };
}
