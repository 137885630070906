import React from 'react';
import { Panel } from 'rsuite';
import CitiesForm from './CitiesForm';
import useResourceEdit from '../../../hooks/useResourceEdit';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';

const CitiesEdit = props => {
  const {
    data,
    onChange,
    onSubmit,
    onDelete,
    onSubmitAndReturn,
  } = useResourceEdit('cities', props.match.params.id);

  return (
    <Panel header="Редактировать город">
      <CitiesForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onDelete={onDelete}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(CitiesEdit);
