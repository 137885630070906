import {Form, FormControl, SelectPicker} from 'rsuite';

import ArrayField from '../../shared/ArrayField';
import Field from '../../shared/Field';
import FieldGroup from '../../shared/FieldGroup';
import FileField from '../../shared/FileField';
import React from 'react';
import TextAreaField from '../../shared/TextAreaField';
import ToggleField from '../../shared/ToggleField';

const courseTypes = [
  {label: 'Видео', value: 'video'},
  {label: 'Аудио', value: 'audio'},
  {label: 'Документ(pdf, doc, xlxs, pptx)', value: 'docs'},
  {label: 'Ссылка на документ', value: 'slides'},
];

const questionTemplate = {
  text: '',
  answers: [],
};

const answerTemplate = {
  text: '',
  correct: false,
};

const LectureForm = ({formValue, onChange, sortId, onOver, onLeave}) => {
  console.log(onOver, onLeave);
  return (
    <Form formValue={formValue} onChange={onChange} fluid>
      <FieldGroup layout={{1: 30, 2: 30, 3: 30, 4: 30, 5: 30}}>
        <Field title='Название'>
          <FormControl name='title' />
        </Field>
        <Field title='Тип'>
          <FormControl name='type' accepter={SelectPicker} data={courseTypes} />
        </Field>
        {formValue.type === 'audio' ? (
          <Field title='Аудио файл'>
            <FormControl name='audio' accept='audio/*' accepter={FileField} />
          </Field>
        ) : formValue.type === 'slides' ? (
          <Field title='Ссылка на документ'>
            <FormControl name='presentationUrl' />
          </Field>
        ) : formValue.type === 'docs' ? (
          <Field title='Документ'>
            <FormControl name='docs' accept='*' accepter={FileField} />
          </Field>
        ) : (
          <Field title='Видео'>
            <FormControl name='video' accept='video/*' accepter={FileField} />
          </Field>
        )}
        <Field title='Количество попыток'>
          <FormControl name='retryCount' type='number' />
        </Field>
        <Field title='Время на тест (секунд)'>
          <FormControl name='testDuration' type='number' />
        </Field>
      </FieldGroup>
      <ArrayField
        name='questions'
        resourceLabel='Вопросы'
        templateElement={questionTemplate}
      >
        <Field title='Текст вопроса'>
          <FormControl name='text' accepter={TextAreaField} />
        </Field>
        <ArrayField
          name='answers'
          resourceLabel='Ответы'
          templateElement={answerTemplate}
        >
          <FieldGroup layout={{1: 70, 2: 20}}>
            <Field title='Текст ответа'>
              <FormControl name='text' accepter={TextAreaField} />
            </Field>

            <Field title='Правильный'>
              <FormControl
                onMouseOver={() => onOver(false)}
                onMouseLeave={() => onLeave(true)}
                name='correct'
                accepter={ToggleField}
              />
            </Field>
          </FieldGroup>
        </ArrayField>
      </ArrayField>
    </Form>
  );
};

export default LectureForm;
