import React from 'react';
import { Panel } from 'rsuite';
import CategoriesForm from './CategoriesForm';
import useResourceCreate from '../../../hooks/useResourceCreate';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';

const CategoriesCreate = () => {
  const { data, onChange, onSubmit, onSubmitAndReturn } = useResourceCreate(
    'categories'
  );
  return (
    <Panel header="Добавить категорию">
      <CategoriesForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(CategoriesCreate);
