import React from 'react';
import { Icon } from 'rsuite';
import News from '../pages/news';
import IconsList from '../pages/icons';
import Faq from '../pages/faq';
import Feedbacks from '../pages/feedbacks';
import Events from '../pages/events';
import Lecturers from '../pages/lecturers';
import Cities from '../pages/cities';
import Specialties from '../pages/specialties';
import Categories from '../pages/categories';
import Courses from '../pages/courses';
import Users from '../pages/users';
import Statics from '../pages/static';
import Stats from '../pages/stats'
import LoadSertificate from '../pages/loadSertificate'

export const navData = [
  {
    key: '1',
    icon: <Icon icon="newspaper-o" />,
    text: 'Новости',
    link: '/news',
    component: News,
  },
  {
    key: '2',
    icon: <Icon icon="calendar" />,
    text: 'Мероприятия',
    link: '/events',
    component: Events,
  },
  {
    key: '3',
    icon: <Icon icon="book" />,
    text: 'Курсы',
    link: '/courses',
    component: Courses,
  },
  {
    key: '4',
    icon: <Icon icon="question" />,
    text: 'FAQ',
    link: '/faq',
    component: Faq,
  },
  {
    key: '5',
    icon: <Icon icon="wechat" />,
    text: 'Обратная связь',
    link: '/feedbacks',
    component: Feedbacks,
  },
  {
    key: '6',
    icon: <Icon icon="file" />,
    text: 'Cтраницы',
    link: '/statics',
    component: Statics,
  },
  {
    key: '7',
    icon: <Icon icon="people-group" />,
    text: 'Пользователи',
    link: '/users',
    component: Users,
  },
  {
    key: '8',
    icon: <Icon icon="address-book" />,
    text: 'Лекторы',
    link: '/lecturers',
    component: Lecturers,
  },
  {
    key: '9',
    icon: <Icon icon="user-analysis" />,
    link: '/stats',
    text:'Статистика',
    component: Stats,
  },
  {
    key: '10',
    icon: <Icon icon="plus-square" />,
    link: '/loadSertificate',
    text:'Загрузить Сертификат',
    component: LoadSertificate,
  },
  {
    key: '11',
    icon: <Icon icon="gear"/>,
    text: 'Фильтры',
    children: [
      {
        key: '11-1',
        icon: <Icon icon="bank" />,
        text: 'Города',
        link: '/cities',
        component: Cities,
      },
      {
        key: '11-2',
        icon: <Icon icon="bookmark" />,
        text: 'Категории',
        link: '/categories',
        component: Categories,
      },
      {
        key: '11-3',
        icon: <Icon icon="mortar-board" />,
        text: 'Специальности',
        link: '/specialities',
        component: Specialties,
      },
    ]
  },
  {
    key: '12',
    hidden: true,
    link: '/icons',
    component: IconsList,
  },  
];
