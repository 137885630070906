import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import NewsEdit from './NewsEdit';
import NewsList from './NewsList';
import NewsCreate from './NewsCreate';

const News = props => {
  return (
    <Switch>
      <Route path={'/news'} component={NewsList} exact />
      <Route path={`/news/create`} component={NewsCreate} />
      <Route path={`/news/:id`} component={NewsEdit} />
    </Switch>
  );
};

export default withRouter(News);
