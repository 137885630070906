import React, { useContext } from 'react';
import { Button, Form } from 'rsuite';
import styles from './index.module.sass';
import { FormValueContext, FormContext } from 'rsuite/es/Form/FormContext';

const ArrayField = ({
  name,
  templateElement,
  resourceLabel,
  children,
  canAdd = true,
  canRemove = true,
}) => {
  const formValue = useContext(FormValueContext);
  const form = useContext(FormContext);
  const value = Array.isArray(formValue[name]) ? formValue[name] : [];
  const onChange = data => form.onFieldChange(name, data);
  const change = index => data => {
    onChange(value.map((x, i) => (i === index ? data : x)));
  };

  const add = () => {
    onChange([...value, templateElement]);
  };
  const remove = index => () => {
    onChange(value.filter((x, i) => i !== index));
  };

  return (
    <>
      {value.map((x, i) => (
        <div className={styles.wrapper}>
          <div className={styles.left}>
            {resourceLabel} {i + 1}.
          </div>
          <Form formValue={x} onChange={change(i)} className={styles.form} fluid>
            {canRemove && (
              <Button onClick={remove(i)} className={styles.remove}>
                Удалить
              </Button>
            )}
            {React.cloneElement(<>{children}</>)}
          </Form>
        </div>
      ))}
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{resourceLabel}</span>
        {canAdd && <Button onClick={add}>Добавить</Button>}
      </div>
    </>
  );
};

export default ArrayField;
