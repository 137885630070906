import {
  Alert,
  Button,
  CheckPicker,
  Col,
  Form,
  FormControl,
  Grid,
  Row,
  SelectPicker,
} from 'rsuite';
import React, {useCallback, useEffect, useState} from 'react';

import ArrayField from '../../shared/ArrayField/BaseArrayField';
import CustomLoader from '../../shared/CustomLoader';
import Field from '../../shared/Field';
import FieldGroup from '../../shared/FieldGroup';
import FileField from '../../shared/FileField';
import LectureFrom from './LectureForm';
import TextAreaField from '../../shared/TextAreaField';
import TextEditor from '../../shared/TextEditor';
import ToggleField from '../../shared/ToggleField';
import axios from 'axios';
import {slugFromString} from '../../../utils/beforeChange';
import useResourceOptions from '../../../hooks/useResourceOptions';

const languages = [
  {value: 'ua', label: 'Украинский'},
  {value: 'ru', label: 'Русский'},
  {value: 'en', label: 'English'},
];

const lectionTemplate = {
  title: '',
  video: '',
  questions: [],
};
//orig
const changeSortId = async (fItemId, sItemId, sortIdFItem, sortIdSItem) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/changeSortId`,
      {fItemId, sItemId, sortIdFItem, sortIdSItem}
    );
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};

const CoursesForm = ({formValue = {}, onChange = console.log}) => {
  const {data: lecturers} = useResourceOptions('lecturers');
  const {data: specialities} = useResourceOptions('specialities');

  const [certificates, setCertificates] = useState(null);
  const [selected, setSelected] = useState(formValue.certificate);
  const [stopSorting, setStopSorting] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  console.log(selected);
  const handleSortEnd = ({oldIndex, newIndex}) => {
    setStopSorting(true);
    const data = formValue.lections;
    const moveData = data.splice(oldIndex, 1);
    const newData = [...data];
    newData.splice(newIndex, 0, moveData[0]);
    changeSortId(
      newData[newIndex].id,
      newData[oldIndex].id,
      newIndex,
      oldIndex
    );

    onChange({...formValue, lections: newData});
  };

  const handleChange = useCallback((value) => {
    onChange(slugFromString(value, 'title'));
  }, []);

  useEffect(() => {
    // set active by default
    onChange({active: true, ...formValue, certificate: selected});
  }, [selected]);

  useEffect(() => {
    async function getCertificates() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getCertificates`
        );
        console.log(res.data);
        setCertificates(res.data);
      } catch (error) {
        Alert.error('Ошибка во время загрузки');
      }
    }
    getCertificates();
  }, []);

  if (certificates === null) return <CustomLoader></CustomLoader>;

  return (
    <Form formValue={formValue} onChange={handleChange} fluid>
      <FieldGroup title={'Основная информация'} layout={{1: 5}}>
        <Field title='Активный'>
          <FormControl name='active' accepter={ToggleField} />
        </Field>
        <Field title='Название'>
          <FormControl name='title' />
        </Field>
        <Field title='Ссылка'>
          <FormControl name='slug' disabled />
        </Field>
        <Field title='Id'>
          <FormControl name='id' disabled />
        </Field>
      </FieldGroup>
      <FieldGroup title={'SEO Информация'}>
        <Field title='SEO Заголовок'>
          <FormControl name='seoTitle' />
        </Field>
        <Field title='SEO Описание'>
          <FormControl name='seoDescription' accepter={TextAreaField} />
        </Field>
      </FieldGroup>
      <FieldGroup
        title={'Детальная иформация'}
        layout={{3: 45, 8: 100, 9: 100, 10: 100}}
      >
        <Field title='Короткое Описание'>
          <FormControl name='shortDescription' accepter={TextAreaField} />
        </Field>
        <Field title='Награда'>
          <FormControl name='reward' type={'number'} />
        </Field>
        <Field title='Картинка'>
          <FormControl name='previewImage' accepter={FileField} />
        </Field>
        <Field title='Язык'>
          <FormControl
            name='language'
            accepter={SelectPicker}
            data={languages}
          />
        </Field>
        <Field title='Лектор'>
          <FormControl
            name='lecturer'
            accepter={SelectPicker}
            data={lecturers}
          />
        </Field>
        <Field title='Специальности'>
          <FormControl
            name='specialities'
            accepter={CheckPicker}
            data={specialities}
          />
        </Field>
        <Field title='Длительность в минутах'>
          <FormControl name='duration' type={'number'} />
        </Field>
        <Field title='Про курс'>
          <FormControl name='about' accepter={TextEditor} />
        </Field>
        <Field title='Описание'>
          <FormControl name='description' accepter={TextEditor} />
        </Field>
        <Field title='Последние слова'>
          <FormControl name='lastWords' />
        </Field>
        <br></br>
        <Field title='Ссылка на клинический случай'>
          <FormControl name='clinicalLink' />
        </Field>
        <br></br>
        <Field title='Баллы за переход по ссылке'>
          <FormControl name='pointsForLink' />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <button
          style={{padding: '10px', borderRadius: '10px'}}
          onClick={() => setIsOpen(!isOpen)}
        >
          Свернуть
        </button>
      </FieldGroup>
      <FieldGroup title={'Лекции'} column style={{position: 'relative'}}>
        <ArrayField
          handleSortEnd={handleSortEnd}
          lections={formValue.lections}
          name='lections'
          resourceLabel='Лекции'
          templateElement={lectionTemplate}
          IterateForm={LectureFrom}
          isDragAndDrop={true}
          stopSorting={stopSorting}
          setStopSorting={setStopSorting}
          lectureId={formValue.id}
          isOpen={isOpen}
        />
      </FieldGroup>
      <Grid fluid>
        <Row>
          <h4 style={{margin: '3vh 0'}}>Выбрать сертификат</h4>
        </Row>

        <Row gutter={50}>
          {certificates.map((c) => (
            <Col xs={10}>
              <img
                style={{width: 'auto', cursor: 'pointer', maxHeight: '250px'}}
                src={c.filename.slice(2)}
                onClick={() => {
                  console.log(c);
                  setSelected(c.filename);
                }}
              ></img>
            </Col>
          ))}
        </Row>

        {formValue.certificate !== null && (
          <>
            <Row>
              <h4 style={{margin: '5vh 0'}}>
                Выбранный{' '}
                <Button
                  onClick={() => {
                    setSelected(null);
                  }}
                >
                  Удалить
                </Button>
              </h4>
              <Row>
                <Col xs={3}>
                  <img
                    src={formValue.certificate?.slice(2)}
                    style={{maxHeight: '400px'}}
                  ></img>
                </Col>
              </Row>
            </Row>
          </>
        )}
      </Grid>
    </Form>
  );
};

export default CoursesForm;
