import slugify from 'slugify';

export function slugFromString(value, fieldName) {

  if (fieldName in value) {
    return {
      ...value,
      slug: slugify(value[fieldName]).toLowerCase(),
      updatedAt: new Date(value.updatedAt).getTime(),
      createdAt: new Date(value.createdAt).getTime(),
    };
  }
  return value;
}
