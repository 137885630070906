import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FeedbackList from './FeedbackList';
import FeedbackDetail from './FeedbackDetail';
import Chat from './Chat'

const Feedbacks = () => (
  <Switch>
    <Route path="/feedbacks" component={FeedbackList} exact />
    <Route path="/feedbacks/chat/:slug" component={Chat} />
    <Route path="/feedbacks/:id" component={FeedbackDetail} />
  </Switch>
);

export default Feedbacks;
