import React, {useEffect, useMemo, useState} from 'react';

import DataTable from '../../shared/DataTable';
import DownloadxlxsBtn from './downloadxlxs';
import {SelectPicker} from 'rsuite';
import {mapCreatedAt} from '../../../utils/mapDataDates';
import useResourceList from '../../../hooks/useResourceList';

const columnsConfig = [
  {
    title: 'Название',
    dataKey: 'title',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Городa',
    dataKey: 'city',
    width: 200,
  },
  {
    title: 'Зарегистрированно',
    dataKey: 'rolled',
    width: 150,
  },
  {
    title: 'Кто Зарегистрировался',
    dataKey: 'whoRegistered',
    width: 200,
  },
  {
    title: 'Сохранили',
    dataKey: 'saved',
    width: 150,
  },
  {
    title: 'Кто сохранил',
    dataKey: 'usersSaved',
    width: 150,
  },
];

function UsersEvents({
  data: {events, users, eventsRegistration, likedEvents, eventsDates},
}) {
  const {data: cities} = useResourceList('cities');
  const [sortValue, setSort] = useState(null);
  const [staticData, setStatic] = useState([]);
  const [isStatic, setIsStat] = useState(false);

  const [secSort, setSec] = useState(null);
  const [isSec, setIsSec] = useState(false);

  const citySortData = cities.map((city) => ({
    label: city.title,
    value: `city ${city.title}`,
    role: 'Город',
  }));

  const eventsSortData = events.map((e) => ({
    label: e.title,
    value: `name ${e.title}`,
    role: 'Название',
  }));

  const data = events.map((e) => {
    const rolledIn = [];
    const saved = [];
    const usersSaved = [];
    const whoRegistered = [];
    const city = [];

    for (let liked of likedEvents) {
      if (e.id === liked.eventId) {
        saved.push(liked);
      }
    }

    for (let registr of eventsRegistration) {
      if (e.id === registr.eventId) {
        rolledIn.push(registr);
        whoRegistered.push(registr);
      }
    }

    for (let user of users) {
      for (let savedUser of saved) {
        if (user.id === savedUser.userId) {
          usersSaved.push(`${user.firstName} ${user.lastName}`);
        }
      }
    }

    for (let info of eventsDates) {
      if (info.event_id === e.id) {
        e = {
          ...e,
          title: `${e.title}`,
        };
        city.push(cities.find((city) => city.id === info.city_id)?.title);
      }
    }

    return {
      ...e,
      rolled: rolledIn.length,
      saved: saved.length,
      usersSaved: usersSaved.join(','),
      whoRegistered: whoRegistered,
      city: city.join(','),
    };
  });

  const mapped = useMemo(() => mapCreatedAt(data), [data]);

  useEffect(() => {
    if (sortValue !== null) {
      const flag = sortValue.slice(0, 4);
      const sortBy = sortValue.slice(4).trim();

      setIsStat(true);

      if (flag === 'city') {
        setStatic(mapped.filter((data) => data.city.includes(sortBy)));
        if (secSort !== null) {
          const sortBySec = secSort.slice(4).trim();

          setStatic(
            mapped
              .filter((data) => data.city.includes(sortBy))
              .filter((data) => data.title.includes(sortBySec))
          );
        }
      }

      if (flag === 'name') {
        setStatic(mapped.filter((data) => data.title.includes(sortBy)));
        if (secSort !== null) {
          const sortBySec = secSort.slice(4).trim();

          setStatic(
            mapped
              .filter((data) => data.title.includes(sortBy))
              .filter((data) => data.city.includes(sortBySec))
          );
        }
      }
    }

    console.log(sortValue && secSort);

    if (sortValue === null) {
      setStatic([]);
      setIsStat(false);
    }
  }, [sortValue, secSort]);

  const dataToPrint = !isStatic
    ? mapped.map((d) => {
        return {
          ['ФИО']: d.whoRegistered
            .map((d) => `${d.name} ${d.surname}`)
            .join(','),
          ['Телефоны']: d.whoRegistered.map((d) => d.phone).join(','),
          ['Название']: d.title,
          ['В каких городах']: d.city,
          email: d.whoRegistered.map((d) => d.email).join(','),
        };
      })
    : staticData.map((d) => {
        return {
          ['ФИО']: d.whoRegistered
            .map((d) => `${d.name} ${d.surname}`)
            .join(','),
          ['Телефоны']: d.whoRegistered.map((d) => d.phone).join(','),
          ['Название']: d.title,
          ['В каких городах']:
            sortValue !== null && sortValue.slice(0, 4) === 'city'
              ? sortValue.slice(4).trim()
              : d.city,
          email: d.whoRegistered.map((d) => d.email).join(','),
        };
      });

  return (
    <div>
      <h4>
        Информация по мероприятиям
        <DownloadxlxsBtn
          data={dataToPrint}
          fileName='Информация по мероприятиям'
        ></DownloadxlxsBtn>
      </h4>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '20px', width: '300px'}}>
          <h5>Город</h5>
          <SelectPicker
            groupBy='role'
            data={[...citySortData]}
            onChange={(v) => {
              if (isSec) {
                setSec(v);
                if (secSort === null) {
                  setIsStat(false);
                  setIsSec(false);
                } else {
                  setIsSec(false);
                }
              } else {
                setSort(v);
                setIsSec(true);
              }
            }}
          ></SelectPicker>
        </div>

        <div style={{margin: 'right', width: '300px'}}>
          <h5>Мероприятия</h5>
          <SelectPicker
            groupBy='role'
            data={[...eventsSortData]}
            onChange={(v) => {
              if (isSec) {
                setSec(v);
                if (secSort === null) {
                  setIsStat(false);
                  setIsSec(false);
                } else {
                  setIsSec(false);
                }
              } else {
                setSort(v);
                setIsSec(true);
              }
            }}
          ></SelectPicker>
        </div>
      </div>
      <DataTable
        data={
          !isStatic
            ? mapped.map((m) => ({
                ...m,
                whoRegistered: m.whoRegistered.map((w) => w.email).join(','),
              }))
            : staticData.map((m) => ({
                ...m,
                whoRegistered: m.whoRegistered.map((w) => w.email).join(','),
              }))
        }
        allowActions={false}
        columns={columnsConfig}
      ></DataTable>
    </div>
  );
}

export default UsersEvents;
