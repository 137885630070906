import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FaqList from './FaqList';
import FaqCreate from './FaqCreate';
import FaqEdit from './FaqEdit';

const Faq = () => (
  <Switch>
    <Route exact path="/faq" component={FaqList} />
    <Route exact path="/faq/create" component={FaqCreate} />
    <Route path="/faq/:id" component={FaqEdit} />
  </Switch>
);

export default Faq;
