import React from 'react';
import { Panel } from 'rsuite';
import CitiesForm from './CitiesForm';
import useResourceCreate from '../../../hooks/useResourceCreate';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';

const CitiesCreate = () => {
  const { data, onChange, onSubmit, onSubmitAndReturn } = useResourceCreate(
    'cities'
  );
  return (
    <Panel header="Добавить город">
      <CitiesForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(CitiesCreate);
