import React from 'react';
import { Dropdown, Icon, Nav } from 'rsuite';

const styles = {
  bottomIcon: {
    width: 56,
    height: 56,
    lineHeight: '56px',
    textAlign: 'center',
  },
};

const BottomNav = ({ expanded }) => (
  <Nav>
    <Dropdown
      placement={expanded ? 'topStart' : 'bottomStart'}
      trigger="click"
      renderTitle={children => {
        return <Icon style={styles.bottomIcon} icon="cog" />;
      }}
    >
      {/*<Dropdown.Item>Help</Dropdown.Item>*/}
      {/*<Dropdown.Item>Settings</Dropdown.Item>*/}
      {/*<Dropdown.Item>Sign out</Dropdown.Item>*/}
    </Dropdown>
  </Nav>
);

export default BottomNav;
