import React, {useEffect, useMemo, useState} from 'react';

import DataTable from '../../shared/DataTable';
import DownloadxlxsBtn from './downloadxlxs';
import {SelectPicker} from 'rsuite';
import {mapCreatedAt} from '../../../utils/mapDataDates';
import useResourceList from '../../../hooks/useResourceList';

const columnsConfig = [
  {
    title: 'Название курса / мероприятия',
    dataKey: 'title',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: '№',
    dataKey: 'number',
    width: 150,
  },
  {
    title: 'Города в которых получили',
    dataKey: 'city',
    width: 250,
  },
  {
    title: 'Сколько выдано',
    dataKey: 'count',
    width: 250,
  },
  {
    title: 'Пользователи',
    dataKey: 'users',
    width: 250
  }
];

function CoursesInfo({data,users}) {
  const {data: cities} = useResourceList('cities');

  const usersWithFullInfo = [];
  users.usersWithEmails.forEach(user => {
    users.users.forEach(u => u.userId === user.id ? usersWithFullInfo.push({...u,...user}) : null)
  });
  
  const [sortVal, setSortVal] = useState(null);
  const [staticData, setStatic] = useState([]);
  const [isStatic, setIsStat] = useState(false);

  const [secSort, setSec] = useState(null);
  const [isSec, setIsSec] = useState(false);


  const citySortData = cities.map((city) => ({
    label: city.title,
    value: `city ${city.title}`,
    role: 'Город',
  }));

  const usersId = data.map(cert => cert.link.split('-')[0]);
  const filteredIds = [...new Set(usersId)]
  
  let usersThatReceived = [];
  usersWithFullInfo.forEach(user => {
    filteredIds.forEach(id => user.id === +id ? usersThatReceived.push(user) : null)
  })
  
  const tempArr = data.map((cert) => `${cert.title}-${cert.number}`);
  const Count = tempArr.map((val) => val.split('-')[0]);

  const withoutDuplicates = [...new Set(tempArr)];

  const arr = [];
  data.forEach(cert => {
    const userId = cert.link.split('-')[0];
    return usersThatReceived.forEach(r => +r.userId === +userId ? arr.push({certId: cert.id, courseId: cert.number,...r,...cert}) : null);
  })

  const collapsed = withoutDuplicates.map((data) => {
    const splitted = data.split('-');
    // console.log(data,'inner')
    const title = splitted[0];
    const number = splitted[1];
    const users = arr.map(data => data.title === title ? data : null).filter(v => v !== null);
    const filtered = Count.filter((val) => val === title);
    return {
      title,
      number: `${number !== null ? number : '-'}`,
      count: `${filtered.length}`,
      users: users.map(u => `Email: ${u.email}, Телефон: ${u.phone}, ФИО: ${u.lastName},${u.firstName},${u.patronymic}. Номер сертификата:${u.courseId}/${u.certId}`).join('\n\n')
      ,users_data: users
    };
  });

  const withCity = collapsed.map((d) => {
    const cities = [];

    const tempArr = data.filter((data) => data.title === d.title);

    tempArr.forEach((d) => cities.push(d.city));

    return {
      ...d,
      city: [...new Set(cities)].join(', '),
    };
  });

  const titlesData = withCity.map((d) => ({
    label: d.title,
    value: `name ${d.title}`,
    role: 'Название',
  }));

  useEffect(() => {
    if (sortVal !== null) {
      const flag = sortVal.slice(0, 4);
      const sortBy = sortVal.slice(4).trim();

      setIsStat(true);

      if (flag === 'city') {
        setStatic(withCity.filter((data) => data.city.includes(sortBy)));
        if (secSort !== null) {
          const sortBySec = secSort.slice(4).trim();

          setStatic(
            withCity
              .filter((data) => data.city.includes(sortBy))
              .filter((data) => data.title.includes(sortBySec))
          );
        }
      }

      if (flag === 'name') {
        setStatic(withCity.filter((data) => data.title.includes(sortBy)));
        if (secSort !== null) {
          const sortBySec = secSort.slice(4).trim();

          setStatic(
            withCity
              .filter((data) => data.title.includes(sortBy))
              .filter((data) => data.city.includes(sortBySec))
          );
        }
      }
    }

    if (sortVal === null) {
      setStatic([]);
      setIsStat(false);
    }
  }, [sortVal, secSort]);

  const mapped = useMemo(() => mapCreatedAt(withCity), [withCity]);

  const dataToPrint = !isStatic?
      arr.map((d) => {
        // console.log(d);
        // console.log(d.users)
        // console.log(d.users_data,'=> users_data');

        return {
          ['Название курса / мероприятия']: d.title,
          ['Номер курса/Номер сертификата']: `${d.courseId}/${d.certId}` ,
          ['Города в которых получили']: sortVal === null ? d.city : sortVal.slice(4).trim(),
          ['email']: d.email,
          ['ФИО']: `${d.lastName},${d.firstName},${d.patronymic}`,
          ['Телефон']: d.phone
        };
        // return dataObj;
      })
    : arr.map((d) => {
      let objData = {};      
      const flag = sortVal.slice(0, 4);
      const sortBy = sortVal.slice(4).trim();

      if(flag === 'name') {
        if(d.title.includes(sortBy)) {
          objData['Название курса / мероприятия'] = d.title;
          objData['Номер курса/Номер сертификата'] = `${d.courseId}/${d.certId}` 
          objData['Города в которых получили'] = d.city
          objData['email'] = d.email
          objData['ФИО'] = `${d.lastName},${d.firstName},${d.patronymic}`
          objData['Телефон'] = d.phone;
        }

        if(secSort !== null) {
          const sortBySec = secSort.slice(4).trim();
          if(d.title.includes(sortBy) && d.city.includes(sortBySec)) {
            objData['Название курса / мероприятия'] = d.title;
            objData['Номер курса/Номер сертификата'] = `${d.courseId}/${d.certId}` 
            objData['Города в которых получили'] =  d.city
            objData['email'] = d.email
            objData['ФИО'] = `${d.lastName},${d.firstName},${d.patronymic}`
            objData['Телефон'] = d.phone;
          }
        }
      }

      if(flag === 'city') {
        if(d.city.includes(sortBy)) {
          objData['Название курса / мероприятия'] = d.title;
          objData['Номер курса/Номер сертификата'] = `${d.courseId}/${d.certId}` 
          objData['Города в которых получили'] = d.city
          objData['email'] = d.email
          objData['ФИО'] = `${d.lastName},${d.firstName},${d.patronymic}`
          objData['Телефон'] = d.phone;
        }

        if(secSort !== null) {
          const sortBySec = secSort.slice(4).trim();
          if(d.city.includes(sortBySec) && d.title.includes(sortBy)) {
            objData['Название курса / мероприятия'] = d.title;
            objData['Номер курса/Номер сертификата'] = `${d.courseId}/${d.certId}` 
            objData['Города в которых получили'] = d.city 
            objData['email'] = d.email
            objData['ФИО'] = `${d.lastName},${d.firstName},${d.patronymic}`
            objData['Телефон'] = d.phone;
          }
        }
      }

      return objData
    })

  return (
    <section style={{marginBottom: '5vh'}}>
      <h4>
        Информация по сертификатам{' '}
        <DownloadxlxsBtn data={dataToPrint} fileName='Информация по сертификатам'></DownloadxlxsBtn>
      </h4>
      <div style={{margin: '20px 0'}}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: '20px', width: '300px'}}>
            <h5>Мероприятия</h5>
            <SelectPicker
              groupBy='role'
              data={[...titlesData]}
              onChange={(v) => {
                if (isSec) {
                  setSec(v);
                  if (secSort === null) {
                    setIsStat(false);
                    setIsSec(false);
                  } else {
                    setIsSec(false);
                  }
                } else {
                  setSortVal(v);
                  setIsSec(true);
                }
              }}
            ></SelectPicker>
          </div>

          <div style={{margin: 'right', width: '300px'}}>
            <h5>Город</h5>
            <SelectPicker
              groupBy='role'
              data={[...citySortData]}
              onChange={(v) => {
                if (isSec) {
                  setSec(v);
                  if (secSort === null) {
                    setIsStat(false);
                    setIsSec(false);
                  } else {
                    setIsSec(false);
                  }
                } else {
                  setSortVal(v);
                  setIsSec(true);
                }
              }}
            ></SelectPicker>
          </div>
        </div>
      </div>

      <DataTable
        data={isStatic ? staticData : mapped}
        allowActions={false}
        columns={columnsConfig}
      ></DataTable>
    </section>
  );
}

export default CoursesInfo;
