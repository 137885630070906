import SchemaForm from '@rsuite/schema-form';
import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

const NewsBlock = SchemaForm([
  {
    key: 'headline',
    label: 'Заголовок',
    type: StringType().isRequired('Необходимо заполнить'),
  },
]);

export default NewsBlock;
