import React from 'react';
import { Link } from 'react-router-dom';

const createListActions = (resourceName, onDelete) => {

  return item => {
    const handleDelete = () => onDelete(item.id);
    return (
      <span>
        <Link to={`/${resourceName}/${item.id}`}>Редактировать</Link> |{' '}
        <a style={{ cursor: 'pointer' }} onClick={handleDelete}>
          Удалить
        </a>
      </span>
    );
  };
};

export default createListActions;
