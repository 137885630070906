import SchemaForm from '@rsuite/schema-form';
import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

const ContactsBlock = SchemaForm([
  {
    key: 'headline',
    label: 'Заголовок',
    type: StringType().isRequired('Необходимо заполнить'),
  },
  {
    key: 'address',
    label: 'Адрес',
    type: StringType().isRequired('Необходимо заполнить'),
  },
  {
    key: 'phone',
    label: 'Телефон',
    type: StringType().isRequired('Необходимо заполнить'),
  },
  {
    key: 'email',
    label: 'Email',
    type: StringType().isRequired('Необходимо заполнить'),
  },
]);

export default ContactsBlock;
