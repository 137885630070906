import SchemaForm from '@rsuite/schema-form';
import { Schema } from 'rsuite';
import TextAreaField from '../../../shared/TextAreaField';

const { StringType } = Schema.Types;

const RegisterBlock = SchemaForm([
  {
    key: 'headline',
    label: 'Заголовок',
    type: StringType().isRequired('Необходимо заполнить'),
  },
  {
    key: 'text',
    label: 'Текст',
    type: StringType().isRequired('Необходимо заполнить'),
    componentClass: TextAreaField
  },
  {
    key: 'buttonText',
    label: 'Текст кнопки',
    type: StringType().isRequired('Необходимо заполнить'),
  },
]);

export default RegisterBlock;
