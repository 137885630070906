import 'moment/locale/ru';

import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  SelectPicker,
} from 'rsuite';
import React, {useEffect, useMemo, useState} from 'react';

import DataTable from '../../shared/DataTable';
import DownloadxlxsBtn from './downloadxlxs';
import axios from 'axios';
import {mapCreatedAt} from '../../../utils/mapDataDates';
import useResourceList from '../../../hooks/useResourceList';

const columnsConfig = [
  {
    title: 'Название',
    dataKey: 'title',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Локация и цена',
    dataKey: 'dates',
    flexGrow: 1,
  },
  {
    title: 'Кто купил',
    dataKey: 'usersWhoPaid',
    flexGrow: 1,
  },
];

const updateKeys = async (newKeys) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/updateKeys`,
    newKeys
  );

  if (res.status === 200) Alert.success('Данные успешно сохранены');
};

function WhoPaid({data: {events, users, eventsDates, whoPaid}}) {
  const {data: cities} = useResourceList('cities');
  const [sortValue, setSort] = useState(null);
  const [staticData, setStatic] = useState([]);
  const [keys, setKeys] = useState({
    key: '',
    secret: '',
  });
  const [isStatic, setIsStat] = useState(false);
  const [secSort, setSec] = useState(null);
  const [isSec, setIsSec] = useState(false);

  const citySortData = cities.map((city) => ({
    label: city.title,
    value: `city ${city.title}`,
    role: 'Город',
  }));

  const eventsSortData = events.map((e) => ({
    label: e.title,
    value: `name ${e.title}`,
    role: 'Название',
  }));

  useEffect(() => {
    async function getKeys() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getKeys`
        );

        setKeys(res.data);
      } catch (error) {
        console.log(error);
      }
    }

    getKeys();
  }, []);

  const data = events.map((e) => {
    const saved = [];
    const usersSaved = [];
    const city = [];
    const dates = [];

    for (let user of users) {
      for (let userWhoPaid of whoPaid) {
        if (
          user.userId === userWhoPaid.userId &&
          e.id === userWhoPaid.courseId
        ) {
          usersSaved.push(
            `ФИО: ${user.firstName} ${user.lastName}. Заплатил: ${
              userWhoPaid.amount
            }${userWhoPaid.sign}. Когда заплатил: ${
              userWhoPaid.date
            }. Родом из: ${cities.find((city) => city.id == user.city)?.title}`
          );
        }
      }
    }

    for (let info of eventsDates) {
      if (info.event_id === e.id) {
        e = {
          ...e,
          title: `${e.title}`,
        };
        city.push(cities.find((city) => city.id === info.city_id)?.title);
      }
    }

    for (let info of eventsDates) {
      if (info.event_id === e.id) {
        dates.push(
          `${cities.find((city) => city.id === info.city_id)?.title} Цена: ${
            info.price
          }${info.priceLabel}\n`
        );
      }
    }

    return {
      ...e,
      saved: saved.length,
      usersWhoPaid: usersSaved.join(' '),
      dates: dates.join(','),
    };
  });

  const mapped = useMemo(() => mapCreatedAt(data), [data]);

  useEffect(() => {
    if (sortValue !== null) {
      const flag = sortValue.slice(0, 4);
      const sortBy = sortValue.slice(4).trim();

      setIsStat(true);

      if (flag === 'city') {
        setStatic(mapped.filter((data) => data.dates.includes(sortBy)));
        if (secSort !== null) {
          const sortBySec = secSort.slice(4).trim();

          setStatic(
            mapped
              .filter((data) => data.dates.includes(sortBy))
              .filter((data) => data.title.includes(sortBySec))
          );
        }
      }

      if (flag === 'name') {
        setStatic(mapped.filter((data) => data.title.includes(sortBy)));
        if (secSort !== null) {
          const sortBySec = secSort.slice(4).trim();

          setStatic(
            mapped
              .filter((data) => data.title.includes(sortBy))
              .filter((data) => data.dates.includes(sortBySec))
          );
        }
      }
    }

    console.log(sortValue && secSort);

    if (sortValue === null) {
      setStatic([]);
      setIsStat(false);
    }
  }, [sortValue, secSort]);

  const dataToPrint =
    staticData.length === 0
      ? mapped.map((d) => {
          return {
            ['Мероприятие']: d.title,
            ['Пользователи']: d.usersWhoPaid,
            ['Город']: d.dates,
          };
        })
      : staticData.map((d) => {
          return {
            ['Мероприятие']: d.title,
            ['Пользователи']: d.usersWhoPaid,
            ['Город']:
              sortValue !== null && sortValue.slice(0, 4) === 'city'
                ? sortValue.slice(4).trim()
                : d.dates,
          };
        });

  return (
    <div>
      <Form style={{margin: '40px 0'}}>
        <h4>Настройки API ключей</h4>
        <FormGroup>
          <ControlLabel>API Ключ</ControlLabel>
          <FormControl
            value={keys.key}
            onChange={(v) => setKeys({...keys, key: v})}
          ></FormControl>
        </FormGroup>
        <FormGroup>
          <ControlLabel>API Секрет</ControlLabel>
          <FormControl
            value={keys.secret}
            onChange={(v) => setKeys({...keys, secret: v})}
          ></FormControl>
        </FormGroup>
        <Button onClick={() => updateKeys(keys)} type='submit'>
          Обновить
        </Button>
      </Form>

      <h4>
        Информация по выплатам за мероприятия{' '}
        <DownloadxlxsBtn
          data={dataToPrint}
          fileName='Информация по выплатам за мероприятия'
        ></DownloadxlxsBtn>
      </h4>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '20px', width: '300px'}}>
          <h5>Город</h5>
          <SelectPicker
            groupBy='role'
            data={[...citySortData]}
            onChange={(v) => {
              if (isSec) {
                setSec(v);
                if (secSort === null) {
                  setIsStat(false);
                  setIsSec(false);
                } else {
                  setIsSec(false);
                }
              } else {
                setSort(v);
                setIsSec(true);
              }
            }}
          ></SelectPicker>
        </div>

        <div style={{margin: 'right', width: '300px'}}>
          <h5>Мероприятия</h5>
          <SelectPicker
            groupBy='role'
            data={[...eventsSortData]}
            onChange={(v) => {
              if (isSec) {
                setSec(v);
                if (secSort === null) {
                  setIsStat(false);
                  setIsSec(false);
                } else {
                  setIsSec(false);
                }
              } else {
                setSort(v);
                setIsSec(true);
              }
            }}
          ></SelectPicker>
        </div>
      </div>

      <DataTable
        data={
          !isStatic
            ? mapped.map((m) => ({
                ...m,
              }))
            : staticData.map((m) => ({
                ...m,
              }))
        }
        allowActions={false}
        columns={columnsConfig}
      ></DataTable>
    </div>
  );
}

export default WhoPaid;
