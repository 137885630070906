import React from 'react';
import { Switch, Route } from 'react-router-dom';
import StaticsList from './StaticsList';
import StaticsEdit from './StaticsEdit';

const Statics = () => (
  <Switch>
    <Route path="/statics" component={StaticsList} exact />
    <Route path="/statics/:id" component={StaticsEdit} exact />
  </Switch>
);

export default Statics;
