import React from 'react';
import { calculateMargin } from '../../../utils/layoutHelpers';
import useValueByScreenWidth from '../../../hooks/useValueByScreenWidth';
import classnames from 'classnames';

import styles from './FieldGroup.module.sass';
import './overrides.sass';

const shouldOverride = () => window.innerWidth <= 1280;

const breakpoints = {
  768: 100,
  1280: 49,
  default: 24,
};

const FieldGroup = ({ title, layout = {}, column, allFull, children }) => {
  const defaultWidth = useValueByScreenWidth(breakpoints);

  return (
    <div className={styles.wrapper}>
      {title && <h4>{title}</h4>}
      <div className={classnames(styles.content, column && styles.content_col)}>
        {Array.isArray(children)
          ? children.map((child, index) => {
              const layoutIndex = index + 1;
              const currentLayout = shouldOverride() ? {} : layout;
              const marginRight = allFull
                ? 0
                : calculateMargin(
                    index,
                    children.length,
                    currentLayout,
                    defaultWidth
                  );
              const width = allFull
                ? 100
                : currentLayout[layoutIndex] || defaultWidth;

              return (
                <div
                  key={index}
                  className={styles.item}
                  style={{
                    width: `${width}%`,
                    marginRight: `${marginRight}%`,
                  }}
                >
                  {child}
                </div>
              );
            })
          : children}
      </div>
    </div>
  );
};

export default FieldGroup;
