import RealApiClient from './ApiClient';
import MockApiClient from './MockApiClient';

let ApiClient;

if (process.env.REACT_APP_CORE_ENV === 'api') {
  ApiClient = RealApiClient;
} else {
  ApiClient = MockApiClient;
}

export default ApiClient;
