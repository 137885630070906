import { useEffect, useState } from 'react';
import useApi from './useApi';

function useResourceOptions(name) {
  const [state, setState] = useState([]);
  const getOptions = useApi(
    'getOptions',
    null,
    'Произошла ошибка при загрузке, попробуйте ещё раз!'
  );

  useEffect(() => {
    getOptions(name).then(setState);
  }, []);

  return { data: state };
}

export default useResourceOptions;
