import SchemaForm from '@rsuite/schema-form';
import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

const MapBlock = SchemaForm([
  {
    key: 'latitude',
    label: 'Широта',
    type: StringType().isRequired('Необходимо заполнить'),
  },
  {
    key: 'longitude',
    label: 'Долгота',
    type: StringType().isRequired('Необходимо заполнить'),
  },
]);

export default MapBlock;
