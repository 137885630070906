import { useState, useEffect, useCallback } from 'react';
import useApi from './useApi';

const useResourceList = resourceName => {
  const [state, setState] = useState([]);
  const getData = useApi(
    'getList',
    null,
    'Произошла ошибка при загрузке, попробуйте ещё раз!'
  );
  const deleteData = useApi('delete', 'Элемент удален', 'Ошибка удаления');

  const getAllData = useCallback(() => {
    getData(resourceName).then(setState);
  }, [resourceName]);

  useEffect(getAllData, []);

  const handleDelete = useCallback(
    id => {
      deleteData(resourceName, id).then(getAllData);
    },
    [resourceName]
  );

  return { data: state, handleDelete };
};

export default useResourceList;
