import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Grid,
  Icon,
  Input,
  Row,
} from 'rsuite';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import axios from 'axios';
import withLoader from '../../hocs/withLoader';

const isAdmin = (id) => (id === 9999 ? true : false);

const sendAdminMsg = async (userId, message) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sendAdminMsg`,
      {userId, message}
    );

    if (res.status === 200) {
      Alert.success('Сообщение успешно отправлено.');
    }
  } catch (error) {
    console.log(error);
  }
};

const seenByAdmin = async (id) =>
  await axios.post(`${process.env.REACT_APP_API_URL}/api/seenByAdmin`, {id});

function Chat() {
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState('');
  const [adminMsg, setAdminMsg] = useState('');
  const [userId, setUserId] = useState('');

  const history = useHistory();
  const {slug} = useParams();

  useEffect(() => {
    async function getUsers() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getallusers`
        );

        setUserId(res.data.users.find((user) => user.email === slug).id);
      } catch (error) {
        console.log(error);
      }
    }

    getUsers();
  }, []);

  useEffect(() => {
    async function getMsgs() {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/getMsg`,
          {userId}
        );

        setMessages(res.data);

        if (res.data.length !== 0) {
          setChatId(res.data[0].chatId);
          seenByAdmin(res.data[0].chatId);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (userId !== '') {
      getMsgs();
    }
  }, [userId]);

  const delChat = async (chatId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/delChat`,
        {chatId}
      );

      if (res.status === 200) {
        Alert.success('Чат успешно удален');
        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delMsg = async (id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/delMsg`,
        {id}
      );

      if (res.status === 200) {
        Alert.success(
          'Сообщение успешно удалено.Обновите страницу чтобы увидеть изменения'
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    setMessages([...messages, {message: adminMsg, userId: 9999, chatId}]);
    setAdminMsg('');
    sendAdminMsg(userId, adminMsg);
  };

  return (
    <div>
      {chatId !== '' && (
        <Button
          color='red'
          onClick={() => delChat(chatId)}
          style={{margin: '20px'}}
        >
          Удалить чат
        </Button>
      )}
      <Grid fluid style={{height: '70vh', overflowY: 'scroll'}}>
        {messages.map((msg) => (
          <Row key={msg.id} style={{padding: '0 50px', margin: '40px 0'}}>
            <Col
              style={{
                position: 'relative',
                background: !isAdmin(msg.userId) ? '#DDE1E1' : '#96E1BA',
                float: isAdmin(msg.userId) ? 'left' : 'right',
                padding: '10px',
                borderRadius: '10px',
                maxWidth: '500px',
              }}
            >
              {isAdmin(msg.userId) && <h5>Вы</h5>}
              <div
                style={!isAdmin(msg.userId) ? {color: '#333'} : {color: '#333'}}
              >
                {msg.message}
                {isAdmin(msg.userId) && (
                  <span
                    style={{
                      position: 'absolute',
                      left: 0,
                      bottom: -20,
                      width: '320px',
                    }}
                  >
                    {msg.seen === null ? 'Не прочитано' : msg.seen}
                  </span>
                )}
              </div>
              <Button
                onClick={() => delMsg(msg.id)}
                style={{
                  position: 'absolute',
                  background: '#E15459',
                  color: '#fff',
                  height: '30px',
                  width: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: -15,
                  right: -15,
                }}
              >
                <Icon icon='close'></Icon>
              </Button>
            </Col>
          </Row>
        ))}
        <Form
          style={{
            marginLeft: '50px',
            position: 'fixed',
            bottom: 10,
            width: '500px',
          }}
        >
          <FormGroup>
            <Input
              componentClass='textarea'
              rows={3}
              style={{width: 300}}
              placeholder='Textarea'
              value={adminMsg}
              onChange={(v) => setAdminMsg(v)}
            />
            <Button
              type='submit'
              onClick={handleSubmit}
              style={{marginTop: '20px'}}
            >
              Отправить
            </Button>
          </FormGroup>
        </Form>
      </Grid>
    </div>
  );
}

export default withLoader(Chat);
