import { Form, FormControl } from 'rsuite';

import ArrayTextField from '../../../shared/ArrayField/ArrayTextField';
import Field from '../../../shared/Field';
import FileField from '../../../shared/FileField';
import React from 'react';
import TextEditor from '../../../shared/TextEditor';
import ToggleField from '../../../shared/ToggleField';

const CountBlock = ({ formValue, onChange }) => (
  <Form formValue={formValue} onChange={onChange}>
    <Field title="Заголовок">
      <FormControl name="headline" />
    </Field>
    <Field title="Текст">
      <FormControl name="text" accepter={TextEditor}  />
    </Field>
    <Field title="Картинка справа">
      <FormControl name="right" accepter={ToggleField} />
    </Field>
    <Field title="Изображение">
      <FormControl name="image" accepter={FileField} />
    </Field>
    <Field title="Тёмный фон">
      <FormControl name="dark" accepter={ToggleField} />
    </Field>
    <Field title="Список">
      <ArrayTextField name="list" resourceLabel="Список" />
    </Field>
  </Form>
);

export default CountBlock;
