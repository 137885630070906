import React from 'react';
import {Switch, Route} from 'react-router-dom';
import LecturersList from './LecturersList';
import LecturersCreate from './LecturersCreate';
import LecturersEdit from './LecturersEdit';

const Lecturers = () => (
  <Switch>
    <Route exact path="/lecturers" component={LecturersList} />
    <Route path="/lecturers/create" component={LecturersCreate} />
    <Route path="/lecturers/:id" component={LecturersEdit} />
  </Switch>
)

export default Lecturers;
