import React,{useState} from 'react'
import {FormControl,FormGroup,ControlLabel,Form,Modal,Button,SelectPicker,Alert} from 'rsuite'
import axios from 'axios';

const roles = [
    {
        label:'Aдмин',
        value:'admin',
        role:'admin'
    },
    {
        label:'Kонтент-менеджер',
        value:'manager',
        role:'manager'
    }
]

function ModalAddAdmin({show,onHide}) {

    const [formValue,setFormValue] = useState({
        email:'',
        role:'',
        password:''
    })

    const handleSubmit = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/createWithRole`,{...formValue});

            if(res.status === 200) {
                onHide();
                Alert.success('Пользователь был добавлен');
                window.location.reload();
            };
        } catch (error) {
            console.log(error)
            onHide();
            Alert.error('Ошибка при добавлении')
        }
   
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>Добавить админа</Modal.Header>
            <Modal.Body>
                <Form fluid>
                <FormGroup>
                    <ControlLabel>Почта</ControlLabel>
                    <FormControl name='email' onChange={(v) => setFormValue({...formValue,email:v})}></FormControl>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Роль</ControlLabel>
                    <SelectPicker data={roles} placeholder='Роль' onChange={(v) => setFormValue({...formValue,role:v})}></SelectPicker>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Пароль</ControlLabel>
                    <FormControl name='password' type='password' onChange={(v) => setFormValue({...formValue,password:v})}></FormControl>
                </FormGroup>
                <FormGroup>
                    <Button type='submit' onClick={() => handleSubmit()} disabled={formValue.role.length < 1 || formValue.password.length < 1}>Добавить</Button>
                </FormGroup>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAddAdmin
