import React from 'react';
import { ControlLabel, FormGroup, HelpBlock } from 'rsuite';

const Field = props => (
  <FormGroup>
    <ControlLabel>{props.title}</ControlLabel>
    {props.children}
    {props.required ? <HelpBlock>Required</HelpBlock> : ''}
  </FormGroup>
);

export default Field;
