import React from 'react';

function UsersRegistred({data: {users}, onlineUsers}) {
  // console.log(data)
  return (
    <div style={{marginBottom: '5vh'}}>
      <h4>
        Oбщее количество пользователей:{' '}
        {users.map((u) => (u.role === 'user' ? u : null)).length}
      </h4>
      <h4>Пользователей online: {onlineUsers.length}</h4>
    </div>
  );
}

export default UsersRegistred;
