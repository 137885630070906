import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { Dropdown, Nav, Sidenav } from 'rsuite';
import { Link } from 'react-router-dom';
import { navData } from './navData';

const Sidebar = ({ expanded }) => {
  const { height } = useWindowSize();

  const navBodyStyle = expanded
    ? {
        height: height - 112,
        overflow: 'auto',
      }
    : {};

  return (
    <Sidenav
      expanded={expanded}
      defaultOpenKeys={['3']}
      activeKey={[]}
      appearance="subtle"
    >
      <Sidenav.Body style={navBodyStyle}>
        <Nav>
          {navData.map(x => {
            if (x.hidden) {
              return false;
            }

            if (x.children) {
              return (
                <Dropdown
                  key={x.key}
                  eventKey={x.key}
                  trigger="click"
                  title={x.text}
                  icon={x.icon}
                >
                  {x.children.map(child => (
                    <Dropdown.Item
                      key={child.key}
                      eventKey={child.key}
                      componentClass={Link}
                      to={child.link}
                      icon={child.icon}
                    >
                      {child.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              );
            }

            return (
              <Nav.Item
                key={x.key}
                eventKey={x.key}
                icon={x.icon}
                componentClass={Link}
                to={x.link}
              >
                {x.text}
              </Nav.Item>
            );
          })}
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  );
};

export default Sidebar;
