import React from 'react';
import { Panel } from 'rsuite';
import SpecialitiesForm from './SpecialitiesForm';
import useResourceCreate from '../../../hooks/useResourceCreate';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';

const SpecialitiesCreate = () => {
  const { data, onChange, onSubmit, onSubmitAndReturn } = useResourceCreate(
    'specialities'
  );
  return (
    <Panel header="Добавить специальность">
      <SpecialitiesForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(SpecialitiesCreate);
