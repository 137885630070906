import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


// const editorConfig = {
//   plugins:[],
//   toolbar: []
// }

const CKEditorComponent = ({ value, onChange }) => (
  <CKEditor
    // config={editorConfig}
    editor={ClassicEditor}
    data={value ? value : ''}
    onChange={(event, editor) => {
      const data = editor.getData();
      onChange(data);
    }}
  />

);

export default CKEditorComponent;


//   <Editor apiKey='ssrwmeavl815miit750dsid4nxy1pwaklaka83va3jkoav6y' onEditorChange={onChange}          init={{
//     height: 500,
//     menubar: false,
//     plugins: [
//       'advlist autolink lists link image charmap print preview anchor',
//       'searchreplace visualblocks code fullscreen',
//       'insertdatetime media table paste code help wordcount'
//     ],
//     toolbar:
//       'undo redo | formatselect | bold italic backcolor | \
//       alignleft aligncenter alignright alignjustify | \
//       bullist numlist outdent indent | removeformat | help'
//   }}
// value={value}></Editor>