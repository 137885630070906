import React from 'react';
import { Icon, Panel } from 'rsuite';
import data from './data.json';
import './index.sass';

const IconsList = () => {
  return (
    <Panel header="Icons">
      <div className="icon-container">
        {data.map(x => (
          <div className="icon">
            {x}:<Icon icon={x} size="2x" />
          </div>
        ))}
      </div>
    </Panel>
  );
};

export default IconsList;
