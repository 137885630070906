import React from 'react';
import { Panel } from 'rsuite';
import SpecialitiesForm from './SpecialitiesForm';
import useResourceEdit from '../../../hooks/useResourceEdit';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';

const SpecialitiesEdit = props => {
  const {
    data,
    onChange,
    onSubmit,
    onDelete,
    onSubmitAndReturn,
  } = useResourceEdit('specialities', props.match.params.id);
  return (
    <Panel header="Редактировать специальность">
      <SpecialitiesForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onDelete={onDelete}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(SpecialitiesEdit);
