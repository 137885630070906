import React from 'react'
import {Form,FormControl,TagPicker,ControlLabel,FormGroup,Input,Checkbox,Button,Alert} from 'rsuite'
import axios from 'axios'

const TOKEN_TURBO = 'b00b201bc25018dbe8d691a44293f96f66f67f0b'
const config = {
    headers:  {"Content-Type":"application/x-www-form-urlencoded"},
    params:   {token: TOKEN_TURBO}
}

function SmsForm({smsForm,onChange}) {

    // const handleChange = (value,checked,e) => {
        // should be unique method to collect all values but for that money it's sucks
    // }

    const sendSms = async () => {
     // for testing change send.json to ping
     const res = await axios.request({
        method:'post',
        url:'https://api.turbosms.ua/message/send.json', // with postman works fine,but on that one thorws an error like "Network:error"
        data:  {  
           "Content-Type":"application/json",
           "recipients":[
              "380980318360",
           ],
           "sms":{
              "sender": "default",
              "text": "not so bad"
           }
        },
        headers: {
           "Content-Type":"application/json"
        },
        params: {
            token:TOKEN_TURBO
        }
    }).catch(function(error) {
        if (!error.response) {
          // network error
          Alert.warning('Оплатите тариф чтобы продолжить использование сервиса')
        } else {
          // http status code
          const code = error.response.status
          // response data
          const response = error.response.data
        }
      });


    }
    
    const sendViber = async () => {
    //    const res = await axios.post('https://api.turbosms.ua/message/send.json',
    // {
    //     "recipients":[
    //        "380980318360",
    //     ],
    //     "viber":{
    //        "sender": `${smsForm.viber.sender}`,
    //        "text": `${smsForm.viber.text}`
    //     },
    //  },config)
    const res = await axios.request({
        method:'post',
        url:'https://api.turbosms.ua/message/send.json', // with postman works fine,but on that one thorws an error like "Network:error"
        data:  {  
           "Content-Type":"application/json",
           "recipients":[
              "380980318360",
           ],
           "viber":{
              "sender": "default",
              "text": "not so bad"
           }
        },
        headers: {
           "Content-Type":"application/json"
        },
        params: {
            token:TOKEN_TURBO
        }
    }).catch(function(error) {
        if (!error.response) {
          // network error
          console.log('network error :(')
          Alert.warning('Оплатите тариф чтобы продолжить использование сервиса')
        } else {
          // http status code
          const code = error.response.status
          // response data
          const response = error.response.data
        }
      });

    }

    const sendTg = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/sendTg`,{text:smsForm.telegram.text});
            console.log(res);
            console.log(res.status);
            if(res.status === 200) {
                onChange({...smsForm,telegram:{...smsForm,isSelected:false,text:''}});
                Alert.success('Сообщение успешно отправлено')
            }    
        } catch (error) {
            Alert.error('Ошибка при отправке')
        }
    }
    
    const handleSubmit = (e) => {
        try {
          if(smsForm.sms.isSelected) sendSms()
          if(smsForm.viber.isSelected) sendViber();
          if(smsForm.telegram.isSelected) sendTg();
        } catch (error) {
            console.log(error,'smsForm')
        }
    }
    
    return (
        <Form>
            <FormGroup style={{marginTop: '20px'}}>
                <h4 style={{display: 'flex'}}>SMS <Checkbox value={smsForm.sms.isSelected} onChange={(_,checked,e) => onChange({...smsForm,sms: {...smsForm.sms,isSelected:checked}})}></Checkbox></h4>
                <ControlLabel style={{marginTop: '20px'}}>От кого</ControlLabel>
                <FormControl name='sender' value={smsForm.sms.sender} onChange={(val) => onChange({...smsForm,sms: {...smsForm.sms,sender:val}})} ></FormControl>
                <ControlLabel style={{marginTop: '20px'}}>Текст</ControlLabel>
                <Input componentClass='textarea' value={smsForm.sms.text} onChange={(val) => onChange({...smsForm,sms: {...smsForm.sms,text:val}})} rows={3} style={{width: 300}} name='smstext'></Input>
            </FormGroup>

            <FormGroup>
                <h4 style={{display: 'flex'}}>Viber <Checkbox value={smsForm.viber.isSelected} onChange={(_,checked,e) => onChange({...smsForm,viber: {...smsForm.viber,isSelected:checked}})}></Checkbox></h4>
                <ControlLabel style={{marginTop: '20px'}}>От кого</ControlLabel>
                <FormControl name='sender' value={smsForm.viber.sender} onChange={(val) => onChange({...smsForm,viber: {...smsForm.viber,sender:val}})}></FormControl>
                <ControlLabel style={{marginTop: '20px'}}>Текст</ControlLabel>
                <Input componentClass='textarea' value={smsForm.viber.text} onChange={(val) => onChange({...smsForm,viber: {...smsForm.viber,text:val}})} rows={3} style={{width: 300}} name='vibertext'></Input>
            </FormGroup>

            <FormGroup>
                <h4 style={{display: 'flex'}}>Telegram <Checkbox checked={smsForm.telegram.isSelected}  onChange={(_,checked,e) => onChange({...smsForm,telegram: {...smsForm.telegram,isSelected:checked}})}></Checkbox></h4>
                <ControlLabel style={{marginTop: '20px'}}>Текст</ControlLabel>
                <Input componentClass='textarea' value={smsForm.telegram.text} onChange={(v) => onChange({...smsForm,telegram:{...smsForm.telegram,text:v}})} rows={3} style={{width: 300}} name='tgtext'></Input>
            </FormGroup>

            <Button type='submit' onClick={handleSubmit} disabled={!smsForm.sms.isSelected && !smsForm.viber.isSelected && !smsForm.telegram.isSelected}>Отправить</Button>
        </Form>
    )
}

export default SmsForm


    //   const res = await axios.post('https://api.turbosms.ua/message/send.json',
    // {  
    //     "Content-Type":"application/x-www-form-urlencoded",
    //     "Content-Type":"application/json",
    //     "recipients":[
    //        "380980318360",
    //     ],
    //     "sms":{
    //        "sender": `${smsForm.sms.sender}`,
    //        "text": `${smsForm.sms.text}`
    //     }
    //  },config)

    //  const res = await axios({
    //      method:'post',
    //      url:'https://api.turbosms.ua/message/ping',
    //      data:  {  
    //         "Content-Type":"application/json",
    //         "recipients":[
    //            "380980318360",
    //         ],
    //         "sms":{
    //            "sender": "defualt",
    //            "text": "not so bad"
    //         }
    //      },
    //      headers: {
    //         "Content-Type":"application/json"
    //      },
    //      params: {
    //          token:TOKEN_TURBO
    //      }
    //  })