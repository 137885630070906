import { Form, FormControl } from 'rsuite';

import Field from '../../../shared/Field';
import FileField from '../../../shared/FileField';
import React from 'react';
import TextAreaField from '../../../shared/TextAreaField';
import TextEditor from '../../../shared/TextEditor';

const TopBlock = ({ formValue, onChange }) => {
  return (
    <Form formValue={formValue} onChange={onChange} fluid>
      <Field title="Заголовок">
        <FormControl name="headline" />
      </Field>
      <Field title="Текст">
        <FormControl name="text" accepter={TextEditor} />
      </Field>
      <Field title="Изображение">
        <FormControl name="image" accepter={FileField} />
      </Field>
      <Field title="Ссылка">
        <FormControl name="buttonLink" />
      </Field>
      <Field title="Текст кнопки">
        <FormControl name="buttonText" />
      </Field>
    </Form>
  );
};

export default TopBlock;
