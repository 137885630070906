import React from 'react';
import { Form, FormControl } from 'rsuite';
import FileField from '../../../shared/FileField';
import ArrayField from '../../../shared/ArrayField';
import Field from '../../../shared/Field';

const ExpertsBlock = ({ formValue, onChange }) => (
  <Form formValue={formValue} onChange={onChange}>
    <Field title="Заголовок">
      <FormControl name="headline" />
    </Field>
    <ArrayField name="experts">
      <Field title="Имя">
        <FormControl name="name" />
      </Field>
      <Field title="Звание">
        <FormControl name="rank" />
      </Field>
      <Field title="Изображение">
        <FormControl name="image" accepter={FileField}/>
      </Field>
    </ArrayField>
  </Form>
);

export default ExpertsBlock;
