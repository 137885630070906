import { useCallback, useEffect, useState } from 'react';
import useApi from './useApi';
import { useHistory } from 'react-router';
import omit from 'lodash/omit';

function useResourceCreate(name, id) {
  const [state, setState] = useState({});
  const history = useHistory();
  const getData = useApi('get');
  const createData = useApi(
    'create',
    'Запись успешно создана',
    'Произошла ошибка при создании, попробуйте ещё раз'
  );

  useEffect(() => {
    if (id) {
      getData(name, id).then(data => setState(omit(data, ['id'])));
    }
  }, []);

  const onChange = useCallback(
    data => {
      setState({ ...state, ...data });
    },
    [state]
  );

  const onSubmit = useCallback(
    redirect => {
      createData(name, state).then(resp => {
        if (redirect) {
          history.push(`/${name}/`);
          return;
        }
        if (resp && resp.id) {
          history.push(`/${name}/${id}`);
        }
      });
    },
    [state]
  );

  const onSubmitAndReturn = useCallback(() => {
    onSubmit(true);
  }, [onSubmit]);

  return {
    data: state,
    onChange,
    onSubmit,
    onSubmitAndReturn,
  };
}

export default useResourceCreate;
