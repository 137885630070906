import React from 'react';
import { Form, FormControl } from 'rsuite';
import Field from '../../../shared/Field';
import ArrayField from '../../../shared/ArrayField';

const FooterBlock = ({ formValue, onChange }) => (
  <Form formValue={formValue} onChange={onChange} fluid>
    <Field title="Текст">
      <FormControl name="text" />
    </Field>
    <Field title="Авторское право">
      <FormControl name="copyright" />
    </Field>
    <Field title="Текст кнопки">
      <FormControl name="buttonText" />
    </Field>
    <ArrayField name="links" resourceLabel="Ссылки">
      <Field title="Текст">
        <FormControl name="title" />
      </Field>
      <Field title="Ссылка">
        <FormControl name="link" />
      </Field>
    </ArrayField>
  </Form>
);

export default FooterBlock;
