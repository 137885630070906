import formatDate from './formatDate';

export default function mapDataDate(data, colName) {
  if (!data || !Array.isArray(data)) {
    return data;
  }
  return data.map((x) => ({
    ...x,
    [colName]: formatDate(x[colName]),
  }));
}

export const mapCreatedAt = (data) => mapDataDate(data, 'createdAt');
export const mapLastSeen = (data) => mapDataDate(data, 'lastSeen');
