import React,{useState,useEffect} from 'react'
import {Form,FormControl,ControlLabel,HelpBlock,FormGroup,Button,Content,FlexboxGrid} from 'rsuite'
import {saveToStorage} from '../../../utils/localStorageAPI'
import axios from 'axios'
import CustomLoader from '../../shared/CustomLoader'

function LoginForm() {
    const [formValue, setFormValue] = useState({
        login:'',
        pass:''
    })

    const [users,setUsers] = useState(null);

    const [error, setError] = useState({
        login:false,
        pass:false,
    })

    useEffect(() => {
        async function getUsers() {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/getAuthUsers`);

            setUsers(res.data);
        }

        getUsers();
    },[])

    if(users === null) return <CustomLoader></CustomLoader>
    
    const handleLogin = (value) => {
        setFormValue({...formValue,login:value})
        if(value !== 'admin' && value !== 'superadmin' && value !== 'manager') {
            setError({...error,login:true})
        }  else {
            setError({...error,login:false})
        }
    }

    const handlePass = (value) => {
            setFormValue({...formValue,pass:value})
            if(value.length < 1) {
                setError({...error,pass:true});
            } else if(users.find(user => user.password === value) || value === 'super12345') {
                setError({...error,pass:false})
            }
    }
    
    

    const handleSubmit = () => {
        if(error.login === false && error.pass === false) {
            window.location.reload();

            if(formValue.login === 'manager') {
                const found = users.find(user => user.password === formValue.pass);
                setError({...error,pass:true});
                setTimeout(() => setError({...error,pass:false}),3000)
                if(found !== undefined ? found.role === formValue.login : false) {
                    setError({...error,pass:false});
                    saveToStorage('token',{
                        token: 'askdjf[apiosuertkajs;dlkfaj;sldkjfa;osidufa;lsdkjf;alskdjf;alisduf34534508uad/kja;slkj;???JMANAGER:SLDJOIIII',
                        role: 'manager'
                    })
                }
            }

            if(formValue.login === 'admin') {
                const found = users.find(user => user.password === formValue.pass);
                setError({...error,pass:true});
                setTimeout(() => setError({...error,pass:false}),3000)
                if(found !== undefined ? found.role === formValue.login : false) {
                    setError({...error,pass:false});
                    saveToStorage('token',{
                        token: 'askdjf[apiosuertkajs;dlkfaj;sldkjfa;osidufa;lsdkjf;alskdjf;alisduf34534508uad/kja;slkj;???J:SKDJF:ADMINSKDJFI__SIDOU?',
                        role: 'admin'
                    })
                }
            }
            
            if(formValue.login === 'superadmin' && formValue.pass === 'super12345') {
                console.log('superadmin')
                saveToStorage('token',{
                    token: 'askdjf[apiosuertkajs;dlkfaj;sldkjfa;osidufa;lsdkjf;alskdjf;alisduf34534508uad/kja;slk123asdfasdfssj2222222222232324;???J:SKDJF:ADMINSKDJFI__SIDOU?',
                    role: 'superadmin'
                })
            }
   
        };
    }

    return (
        <Content style={{minHeight: '100vh'}}>
            <FlexboxGrid style={{position:'absolute',top:'50%',left: '50%',transform:'translate(-50%,-50%)'}} justify='center'>
                    <Form>
                        <FormGroup>
                            <ControlLabel>Логин</ControlLabel>
                            <FormControl onChange={handleLogin} name='login'></FormControl>
                            {error.login && <HelpBlock  style={{color:'red'}}>Неправильный логин</HelpBlock> }  
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Пароль</ControlLabel>
                            <FormControl onChange={handlePass} type='password' name='pass'></FormControl>
                        {error.pass && <HelpBlock style={{color:'red'}}>Неправильный пароль</HelpBlock>}
                        </FormGroup>
                        <FormGroup>
                            <Button type='submit' onClick={handleSubmit} appearance="default" disabled={formValue.login.length < 1 || formValue.pass.length < 1}>Войти</Button>
                        </FormGroup>
                    </Form>
            </FlexboxGrid>
        </Content>
    )
}

export default LoginForm;
