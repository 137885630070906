import React from 'react';
import { Panel } from 'rsuite';
import CoursesForm from './CoursesForm';
import useResourceCreate from '../../../hooks/useResourceCreate';
import withLoader from '../../hocs/withLoader';
import ActionButtons from '../../shared/ActionButtons';
import usePreloadId from '../../../hooks/usePreloadId';

const CoursesCreate = props => {
  const preloadId = usePreloadId(props);
  const { data, onChange, onSubmit, onSubmitAndReturn } = useResourceCreate(
    'courses',
    preloadId
  );
  return (
    <Panel header="Создать курс">
      <CoursesForm formValue={data} onChange={onChange} />
      <ActionButtons
        onSubmit={onSubmit}
        onSubmitAndReturn={onSubmitAndReturn}
      />
    </Panel>
  );
};

export default withLoader(CoursesCreate);
