import React, {useMemo, useState, useEffect} from 'react';
import useResourceList from '../../../hooks/useResourceList';
import {Panel, Button, SelectPicker, Alert, Icon} from 'rsuite';
import DataTable from '../../shared/DataTable';
import ActionPanel from '../../shared/ActionPanel';
import withLoader from '../../hocs/withLoader';
import {mapLastSeen} from '../../../utils/mapDataDates';
import {getFromStorage, saveToStorage} from '../../../utils/localStorageAPI';
import {Link} from 'react-router-dom';
import ModalAddAdmin from './ModalAddAdmin';
import ModalAddUser from './ModalAddUser';
import ModalEmail from './ModalEmail';
import axios from 'axios';
import Downloadxlxs from '../stats/downloadxlxs';

const columnsConfig = [
  {
    title: 'Email',
    dataKey: 'email',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Специальность',
    dataKey: 'specialization',
    flexGrow: 1,
  },
  {
    title: 'Регион',
    dataKey: 'city',
    width: 200,
  },
  {
    title: 'Заблокирован',
    dataKey: 'isBlocked',
    width: 100,
  },
  {
    title: 'Пол',
    dataKey: 'gender',
    width: 50,
  },
  {
    title: 'Роль',
    dataKey: 'role',
    width: 60,
  },
  {
    title: 'Был в сети',
    dataKey: 'lastSeen',
    width: 170,
  },
];

const UsersList = (props) => {
  const [profiles, setProfiles] = useState();
  const token = getFromStorage('token');

  const {data: specialities} = useResourceList('specialities');
  const {data: cities} = useResourceList('cities');

  const citiesSelectData = cities.map((c) => ({
    value: `city ${c.title}`,
    role: 'Регион',
    label: c.title,
  }));
  const specialitiesSelectData = specialities.map((s) => ({
    value: `spec ${s.title}`,
    role: 'Специальность',
    label: s.title,
  }));

  const sortData = [
    {label: 'Мужчина', value: 'gend М', role: 'Пол'},
    {label: 'Женщина', value: 'gend Ж', role: 'Пол'},
    ...citiesSelectData,
    ...specialitiesSelectData,
  ];

  const [sortVal, setSortVal] = useState(getFromStorage('sortData') || null);
  const [staticData, setStaticData] = useState([]);
  const [balances, setBalances] = useState([]);
  const [info, setInfo] = useState([]);
  const [isOpen, setIsOpen] = useState({
    adminModal: false,
    userModal: false,
    emailModal: false,
  });

  const {data, handleDelete} = useResourceList('users');

  useEffect(() => {
    async function getBalances() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getAllBalances`
        );
        const info = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getstats`
        );
        setInfo(info.data);
        setBalances(res.data);
      } catch (error) {
        console.log(error);
      }
    }

    getBalances();
  }, []);

  const getGender = (gen) =>
    typeof gen === 'string' ? (gen === 'male' ? 'М' : 'Ж') : '-';

  const collapsedData = data.map((data) => {
    if (profiles !== undefined) {
      for (let profile of profiles) {
        if (profile.userId === data.id) {
          return {
            ...profile,
            ...data,
            gender: getGender(profile.gender),
          };
        }
      }
    }
  });

  const dataWithSpec = collapsedData.map((data) => {
    if (data !== undefined) {
      for (let spec of specialities) {
        if (spec.speciality_id == data.specialization)
          return {
            ...data,
            specialization: spec.title,
          };
      }
    }
  });

  const tableData = dataWithSpec
    .map((data) => {
      if (data !== undefined) {
        for (let city of cities) {
          if (city.city_id == data.city)
            return {
              ...data,
              isBlocked: data.isBlocked === 'true' ? 'Да' : 'Нет',
              city: city.title,
            };
        }
      }
    })
    .filter((v) => v !== undefined);

  useEffect(() => {
    if (sortVal !== null) {
      const flag = sortVal.slice(0, 4);
      const sortBy = sortVal.slice(4).trim();

      if (flag === 'city')
        setStaticData(tableData.filter((data) => data.city.includes(sortBy)));

      if (flag === 'spec')
        setStaticData(
          tableData.filter((data) => data.specialization.includes(sortBy))
        );

      if (flag === 'gend')
        setStaticData(tableData.filter((data) => data.gender.includes(sortBy)));
    }

    if (sortVal === null) {
      setStaticData([]);
    }
  }, [sortVal]);

  const mapped = useMemo(
    () => mapLastSeen(tableData.filter((v) => v !== undefined)),
    [tableData.filter((v) => v !== undefined)]
  );

  useEffect(() => {
    async function getProfiles() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getallusers`
        );

        setProfiles(res.data.profiles);
      } catch (error) {
        console.log(error);
      }
    }

    getProfiles();
  }, []);

  const deleteUser = async (id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/deleteUser`,
        {id}
      );

      if (res.status === 200) {
        Alert.success('Пользователь успешно удален');
      }
    } catch (error) {
      Alert.error('Ошибка');
    }
  };

  const toPrint =
    staticData.length === 0
      ? mapped.map((d) => {
          const balance = balances.find((b) => b.userId === d.id);
          const courses =
            info.length !== 0 &&
            info.completed.filter((cours) => cours.userId == d.id);

          const coursesData =
            info.length !== 0 &&
            info.courses
              .map((c) => {
                for (let x of courses) {
                  if (x.courseId == c.id) {
                    if (c.title !== undefined) return c.title;
                  }
                }
              })
              .filter((c) => c !== undefined)
              .join(',');

          const filteredEvents =
            info.length !== 0 &&
            info.events
              .map((e) => {
                for (let registr of info.eventsRegistration) {
                  if (e.id === registr.eventId) {
                    if (registr.email == d.email.trim()) {
                      return e.title;
                    }
                  }
                }
              })
              .filter((e) => e !== undefined)
              .join(',');

          return {
            ['Имя']: d.firstName,
            ['Фамилия']: d.firstName,
            ['Отчество']: d.patronymic,
            email: d.email,
            ['Номер Диплом']: d.diploma,
            ['Специализация']: d.specialization,
            ['Город']: d.city,
            ['Заблокирован']:
              d.isBlocked === 'Да' ? 'Заблокирован' : 'Не заблокирован',
            ['Баллы']: `Осталось:${
              balance !== undefined && balance.pointsLeft
            },Баллы:${balance !== undefined && balance.points}`,
            ['Курсы']: coursesData,
            ['Мероприятия']: filteredEvents,
            ['Был в сети']: d.lastSeen,
            ['Телефон']: d.phone !== null ? d.phone : '-',
          };
        })
      : staticData.map((d) => {
          const balance = balances.find((b) => b.userId === d.id);
          const courses =
            info.length !== 0 &&
            info.completed.filter((cours) => cours.userId == d.id);

          const coursesData =
            info.length !== 0 &&
            info.courses
              .map((c) => {
                for (let x of courses) {
                  if (x.courseId == c.id) {
                    if (c.title !== undefined) return c.title;
                  }
                }
              })
              .filter((c) => c !== undefined)
              .join(',');

          const filteredEvents =
            info.length !== 0 &&
            info.events
              .map((e) => {
                for (let registr of info.eventsRegistration) {
                  if (e.id === registr.eventId) {
                    if (registr.email == d.email.trim()) {
                      return e.title;
                    }
                  }
                }
              })
              .filter((e) => e !== undefined)
              .join(',');

          return {
            ['Имя']: d.firstName,
            ['Фамилия']: d.firstName,
            ['Отчество']: d.patronymic,
            email: d.email,
            ['Номер Диплом']: d.diploma,
            ['Специализация']: d.specialization,
            ['Город']: d.city,
            ['Заблокирован']:
              d.isBlocked === 'Да' ? 'Заблокирован' : 'Не заблокирован',
            ['Баллы']: `Осталось:${balance.pointsLeft},Баллы:${balance.points}`,
            ['Курсы']: coursesData,
            ['Мероприятия']: filteredEvents,
            ['Был в сети']: d.lastSeen,
            ['Телефон']: d.phone !== null ? d.phone : '-',
          };
        });

  return (
    <Panel header='Пользователи'>
      <div style={{display: 'flex', marginBottom: '2vh', alignItems: 'center'}}>
        {token.role === 'superadmin' && (
          <>
            <Button
              onClick={() =>
                setIsOpen({...isOpen, adminModal: !isOpen.adminModal})
              }
            >
              Добавить админа
            </Button>
            <ModalAddAdmin
              show={isOpen.adminModal}
              onHide={() =>
                setIsOpen({...isOpen, adminModal: !isOpen.adminModal})
              }
            ></ModalAddAdmin>
            <Button
              onClick={() =>
                setIsOpen({...isOpen, userModal: !isOpen.userModal})
              }
              style={{marginLeft: '20px'}}
            >
              Добавить пользователя
            </Button>
            <ModalAddUser
              show={isOpen.userModal}
              onHide={() =>
                setIsOpen({...isOpen, userModal: !isOpen.userModal})
              }
            ></ModalAddUser>
          </>
        )}

        <Button
          style={{marginLeft: '20px', marginRight: '20px'}}
          onClick={() => props.history.push('/email-configuration')}
        >
          Настройки рассылок
        </Button>
        {/* download button */}
        <Downloadxlxs data={toPrint} fileName={'Пользователь'}></Downloadxlxs>
        {/* download button */}

        <Button
          color='cyan'
          style={{marginLeft: '20px'}}
          onClick={() => setIsOpen({...isOpen, emailModal: !isOpen.emailModal})}
        >
          Отправить этой группе email
        </Button>
        <ModalEmail
          show={isOpen.emailModal}
          onHide={() => setIsOpen({...isOpen, emailModal: !isOpen.emailModal})}
          toWhom={staticData.length === 0 ? mapped : staticData}
        ></ModalEmail>
        <div
          style={{
            width: '300px',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{width: '90%'}}>
            Сортировать по:
            <div>
              <SelectPicker
                data={sortData}
                groupBy='role'
                defaultValue={''}
                onChange={(v) => setSortVal(v)}
              ></SelectPicker>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        data={staticData.length === 0 ? mapped : mapLastSeen(staticData)}
        resourceName='users'
        columns={columnsConfig}
        renderActions={(item) => (
          <>
            {item.role === 'user' && (
              <Link to={`/users/${item.id}`}>Смотреть</Link>
            )}{' '}
            {item.role === 'user' && '|'}{' '}
            {token.role === 'admin' ||
              (token.role === 'superadmin' && (
                <Link onClick={() => deleteUser(item.id)}>Удалить</Link>
              ))}
          </>
        )}
        onDelete={handleDelete}
      />
    </Panel>
  );
};

export default withLoader(UsersList);
