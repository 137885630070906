import {Alert, Button, Input, Panel, TagPicker} from 'rsuite';
import React, {useEffect, useState} from 'react';

import DataTable from '../../shared/DataTable';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {getFromStorage} from '../../../utils/localStorageAPI';
import {mapCreatedAt} from '../../../utils/mapDataDates';
import useResourceList from '../../../hooks/useResourceList';
import withLoader from '../../hocs/withLoader';

const token = getFromStorage('token');

const columnsConfig = [
  {
    title: 'Имя пользователя',
    dataKey: 'name',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Дата создания',
    dataKey: 'createdAt',
    width: 200,
  },
];

const chatsConfig = [
  {
    title: 'Email',
    dataKey: 'email',
    align: 'left',
    flexGrow: 1,
  },
  {
    title: 'Есть ли новые уведомления',
    dataKey: 'newAdminMsg',
    width: 200,
  },
];

const expertsConfig = [
  {
    title: 'Email',
    dataKey: 'email',
    align: 'left',
    width: 300
  },
  {
    title: 'Имя',
    dataKey: 'fName',
    width: 200,
  },
  {
    title: 'Фамилия',
    dataKey: 'lName',
    width: 200,
  },
  {
    title: 'Отчество',
    dataKey: 'patr',
    width: 200,
  },
  {
    title: 'Телефон',
    dataKey: 'phone',
    width: 300,
  },
  {
    title: 'Место работы',
    dataKey: 'workPlace',
    width: 200,
  },
];

const sendToAll = async (message) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sendToAll`,
      {message}
    );

    if (res.status === 200) {
      Alert.success('Сообщения успешно отправлено.');
    }
  } catch (error) {
    console.log(error);
  }
};

const sendToEmails = async ({message, emails}) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sendToEmails`,
      {message, emails: emails.split(' ')}
    );

    if (res.status === 200) {
      Alert.success('Сообщения успешно отправлено.');
    }
  } catch (error) {
    console.log(error);
  }
};

const sendAdminMsg = async (userId, message) => {
  console.log(message);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sendAdminMsg`,
      {userId, message}
    );

    if (res.status === 200) {
      Alert.success('Сообщения успешно отправлено.');
    }
  } catch (error) {
    console.log(error);
  }
};

const udpateTemplate = async (message) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/updateTwoWeeksTemplate`,
      {message}
    );

    if (res.status === 200) {
      Alert.success('Обновлено успешно.');
    }
  } catch (error) {
    console.log(error);
  }
};


const FeedbackList = () => {
  const {data} = useResourceList('feedbacks');
  const {data: users} = useResourceList('users');

  const [text, setText] = useState('')
  const [chats, setChats] = useState([]);
  const [toWhom, setToWhom] = useState([]);
  const [msgToGroup, setMsgToGroup] = useState('');
  const [messages, setMsgs] = useState([]);
  const [msgToAll, setToAll] = useState('');
  const [userByEmail, setWithEmail] = useState({
    emails: '',
    message: '',
  });
  const [experts,setExperts] = useState([]);

  useEffect(() => {
    async function getChats() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getChats`
        );

        setChats(res.data.chats);
        setMsgs(res.data.messages);
      } catch (error) {
        console.log(error);
      }
    }

    async function getExperts() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getExperts`
        );

        setExperts(res.data);
      } catch (error) {
        console.log(error);
      }
    }

    async function getTemplate() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getTwoWeeksTemplate`
        );

        setText(res.data.text);
      } catch (error) {
        console.log(error);
      }
    }

    getTemplate();
    getChats();
    getExperts();
  }, []);

  const registered = [];

  const collapsed = users
    .map((u) => {
      for (let chat of chats) {
        if (chat.userId === u.id)
          return {
            ...u,
            ...chat,
            newAdminMsg:
              chat.newAdminMsg == 'false' || chat.newAdminMsg === null
                ? 'Нет'
                : 'Да',
          };
      }
    })
    .filter((v) => v !== undefined);

  collapsed.forEach((user) => {
    for (let value of data) {
      if (user.email === value.email) return registered.push(user);
    }
  });

  const groupToSend = collapsed.map((u) => ({
    label: u.email,
    value: u.userId,
  }));

  console.log(experts);

  return (
    <Panel header='Обратная связь'>

    <h4>Текст для оповещания об окончании сертификата </h4>
      <Input
        componentClass='textarea'
        rows={3}
        style={{width: 300}}
        placeholder='Textarea'
        value={text}
        onChange={(v) => setText(v)}
      />
      <Button
        style={{marginTop: '20px', marginBottom: '30px'}}
        type='submit'
        onClick={() => {
          udpateTemplate(text);
        }}
      >
        Отправить
      </Button>

      <DataTable
        data={mapCreatedAt(data)}
        resourceName='feedbacks'
        columns={columnsConfig}
        renderActions={(item) => {
          return (
            <>
              <Link to={`/feedbacks/${item.id}`}>Смотреть</Link>{' '}
            </>
          );
        }}
      />

      <h4>Хочу стать экспертом</h4>
      <DataTable
        data={experts}
        columns={expertsConfig}
        allowActions={false}
      />

      <h4>Чаты</h4>
      <h5>Определенным чатам</h5>
      <TagPicker
        onChange={(v) => setToWhom(v)}
        data={groupToSend}
        style={{margin: '20px 0'}}
      ></TagPicker>
      <Input
        componentClass='textarea'
        rows={3}
        style={{width: 300}}
        placeholder='Textarea'
        value={msgToGroup}
        onChange={(v) => setMsgToGroup(v)}
      />
      <Button
        style={{marginTop: '20px', marginBottom: '30px'}}
        type='submit'
        onClick={() => {
          setMsgToGroup('');
          console.log(toWhom)
          toWhom.forEach((u) => sendAdminMsg(u, msgToGroup));
        }}
      >
        Отправить
      </Button>

      <h5 style={{marginBottom: '20px'}}>С помощью Email</h5>

      <h5>Поле для Email'ов</h5>
      <Input
        componentClass='textarea'
        rows={3}
        style={{width: 300}}
        placeholder='Textarea'
        value={userByEmail.emails}
        onChange={(v) => setWithEmail({...userByEmail, emails: v})}
      />
      <h5>Сообщение</h5>
      <Input
        componentClass='textarea'
        rows={3}
        style={{width: 300}}
        placeholder='Textarea'
        value={userByEmail.message}
        onChange={(v) => setWithEmail({...userByEmail, message: v})}
      />
      <Button
        style={{marginTop: '20px', marginBottom: '30px'}}
        type='submit'
        onClick={() => {
          setWithEmail({emails: '', message: ''});
          sendToEmails(userByEmail);
        }}
      >
        Отправить
      </Button>

      <h5>Отправить сообщение всем</h5>
      <Input
        componentClass='textarea'
        rows={3}
        style={{width: 300}}
        placeholder='Textarea'
        value={msgToAll}
        onChange={(v) => setToAll(v)}
      />
      <Button
        style={{marginTop: '20px', marginBottom: '30px'}}
        type='submit'
        onClick={() => {
          setToAll('');
          sendToAll(msgToAll);
        }}
      >
        Отправить
      </Button>

      <DataTable
        data={collapsed}
        resourceName='feedbacks'
        columns={chatsConfig}
        renderActions={(item) => {
          return <Link to={`/feedbacks/chat/${item.email}`}>Чат</Link>;
        }}
      />
    </Panel>
  );
};

export default withLoader(FeedbackList);
