import React,{useState} from 'react'
import {FormControl,FormGroup,ControlLabel,Form,Modal,Button,Alert,SelectPicker} from 'rsuite'
import axios from 'axios';

function ModalAddUser({show,onHide}) {

    const [formValue,setFormValue] = useState({
        firstName: '',
        lastName:'',
        email:'',
        university:'',
        password:'',
        gender:'male',
        city: 1,
        diploma: 1,
        passwordRepeat:'',
        patronymic:'fff',
        specialization:1,
        university:1,
        universityEndDate:null
    })

    const handleSubmit = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/createuser`,{...formValue,passwordRepeat:formValue.password});
            if(res.status === 200) {
                onHide();
                Alert.success('Пользователь был добавлен')
            };
        } catch (error) {
            console.log(error)
            onHide();
            Alert.error('Ошибка при добавлении')
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>Добавить пользователя</Modal.Header>
            <Modal.Body>
                <Form fluid>
                <FormGroup>
                    <ControlLabel>Имя</ControlLabel>
                    <FormControl name='fName' onChange={(v) => setFormValue({...formValue,firstName:v})}></FormControl>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Фамилия</ControlLabel>
                    <FormControl name='lName' onChange={(v) => setFormValue({...formValue,lastName:v})}></FormControl>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Почта</ControlLabel>
                    <FormControl name='email' onChange={(v) => setFormValue({...formValue,email:v})}></FormControl>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Пароль</ControlLabel>
                    <FormControl name='password' type='password' onChange={(v) => setFormValue({...formValue,password:v})}></FormControl>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Пол</ControlLabel>
                    <SelectPicker placeholder="Пол" data={[{label:'Мужской',value:'male',role:'user'},{label:'Женский',value:'female',role:'user'}]} onChange={(v) => setFormValue({...formValue,gender:v})}></SelectPicker>
                </FormGroup>
                <FormGroup>
                    <Button onClick={handleSubmit} disabled={formValue.firstName.length < 1 || formValue.lastName.length < 1 || formValue.email.length < 1 || formValue.password.length < 1}>Добавить</Button>
                </FormGroup>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAddUser
